import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import Environment from '../views/Environment';
import './components.css';

function NewSupplierModal(props) {

  const [formError, setFormError] = useState('');
  const [newSupplier, setNewSupplier] = useState({phoneCode: '52', selectedDays:[], longDescription:''});
  const daysOfWeek = [
    {1:'Lunes'},
    {2:'Martes'},
    {3:'Miércoles'},
    {4:'Jueves'},
    {5:'Viernes'},
    {6:'Sábado'},
    {7:'Domingo'}
  ];
  const [variants, setVariants] =  useState({
    1:'outline-secondary',
    2:'outline-secondary',
    3:'outline-secondary',
    4:'outline-secondary',
    5:'outline-secondary',
    6:'outline-secondary',
    7:'outline-secondary',
  });

  function updateDay(value) {
    return setVariants((prev) => ({ ...prev, ...value }));
  }

  function setDay(e){
    e.preventDefault();
    const idDay = e.currentTarget.id;
    const variant = variants[idDay];
    let selectedDaysCopy = newSupplier['selectedDays'];

    if(variant === 'outline-secondary'){
      updateDay({[idDay]:'secondary'});
      selectedDaysCopy.push(idDay);
    }
    else{
      updateDay({[idDay]:'outline-secondary'});
      const index = selectedDaysCopy.indexOf(idDay);
      selectedDaysCopy.splice(index, 1);
    }
    
    updateForm({'selectedDays':selectedDaysCopy});
      
  }

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setNewSupplier((prev) => ({ ...prev, ...value }));
  }

   /**
   * onSubmit
   * @description Posts package product through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(newSupplier.selectedDays.length > 0){
        fetch(`${Environment()}/suppliers/getSupplierByName/${newSupplier.name}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
          if(data.length){
            setFormError("El proveedor ya existe.");
            return;
          }
          else{
            fetch(`${Environment()}/suppliers/postSupplier`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(newSupplier)
            })
            .then(() => {
              window.location.reload();
            })
            .catch(error => {
              console.log(error);
              setFormError('Lo sentimos, en este momento no es posible procesar tu solicitud.');
            });
          }
        })
        .catch(error => {
          console.log(error);
          setFormError('Lo sentimos, en este momento no es posible procesar tu solicitud.');
        });
      }
      else{
        setFormError('Selecciona por lo menos un día de la semana.')
      }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputSupplierName"><b>Nombre*</b></Form.Label>
              <Form.Control
                required
                type="text"
                id="inputSupplierName"
                maxLength={32} 
                aria-describedby="supplierName"
                placeholder="Hortalizas Pérez"
                onChange={(e) => updateForm({ name: e.target.value })}
                defaultValue={newSupplier.name}
              />
            </Form.Group>
            <Form.Group  className="mb-3">
              <Form.Label className='green' htmlFor="inputSupplierDesc"><b>Descripción corta*</b></Form.Label>
              <Form.Control
                required
                type="text"
                maxLength={255} 
                id="inputSupplierDesc"
                aria-describedby="supplierDescription"
                placeholder="Hortalizas, bulbos, raíces y más"
                onChange={(e) => updateForm({ shortDescription: e.target.value })}
                defaultValue={newSupplier.shortDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputSupplierLongDesc"><b>Descripción larga</b></Form.Label>
              <Form.Control
                as="textarea" rows={3}
                id="inputSupplierLongDesc"
                aria-describedby="SupplierLongDescription"
                placeholder="Hortalizas Pérez tiene más de 10 años produciendo alimentos orgánicos en Querétaro."
                onChange={(e) => updateForm({ longDescription: e.target.value })}
                onKeyUp={(e) => updateForm({ longDescription: e.target.value })}
                defaultValue={newSupplier.longDescription}
                />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Entregas a clientes</b></Form.Label>
              <p className='text-muted'>Selecciona los días de la semana que se entregarán los productos de este proveedor a los clientes de Terra Viva.</p>
              <InputGroup className="mb-3">
                { 
                  daysOfWeek.map((day, idx) => {
                    return(
                    <Button 
                    variant={variants[idx+1]} 
                    id={idx+1} 
                    key={idx+1}
                    onClick={(e) => {setDay(e)}}>{day[idx+1]}</Button>)
                  })
                }
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Dirección*</b></Form.Label>
              <Form.Control 
                required 
                type="text" 
                maxLength={64} 
                onChange={(e) => updateForm({ address: e.target.value })}
                defaultValue={newSupplier.address}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3">
              <Form.Label className='green'><b>Teléfono*</b></Form.Label>
              <InputGroup>
                <DropdownButton
                  variant="outline-secondary"
                  title={newSupplier.phoneCode}
                  id="input-group-dropdown-2"
                  align="end"
                  required
                >
                  <Dropdown.Item onClick={(e) => updateForm({ phoneCode: '1' })}>+1</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => updateForm({ phoneCode: '52' })}>+52</Dropdown.Item>
                </DropdownButton>
                <Form.Control 
                  required 
                  type="tel" 
                  id="phone" 
                  maxLength={10}
                  onChange={(e) => updateForm({ phone: e.target.value })}
                  defaultValue={newSupplier.phone}
                />
              </InputGroup>
            </Form.Group>
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewSupplierModal;