import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Card from 'react-bootstrap/esm/Card';
import Form from 'react-bootstrap/esm/Form';
import Spinner from 'react-bootstrap/esm/Spinner';
import Environment from './Environment';
import AdminBar from '../components/AdminBar';
import ModifyClientOrder from '../components/modifyClientOrder';
import './views.css';
const libraries = ["places"];

function OrderDelivery() {
  const location = useLocation();
  const [postalCodes] = useState(location.state.postalCodes);
  const [coordinates] = useState(location.state.coordinates);
  const [simulation] = useState(location.state.simulation);
  const [orders, setOrders] = useState([]);
  const [productList, setProductList] = useState([]);
  const [form, setForm] = useState(0);
  const [productOrders, setProductOrders] = useState(0);
  const [waypointOrder, setWaypointOrder] = useState([]);
  const [error, setError] = useState('');
  const [tabNumber, setTabNumber] = useState(0);
  const [orderIdx, setOrderIdx] = useState(null);
  const [map, setMap] = useState(true);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0
  });
  const [editMode, setEditMode] = useState(false);
  const [cancelCounter, setCancelCounter] = useState(0);
  const [updatedProduct, setUpdatedProduct] = useState(0);
  const isFirstRun = useRef(true);

  useEffect(() => {
    const keys = Object.keys(postalCodes);
    let temp = [];
    keys.forEach(group => {
        if(postalCodes[group]['status'] === true){
          postalCodes[group]['orders'].forEach(order => {
            temp.push(parseInt(order.idOrder));
          });
        }
    });

    temp = encodeURIComponent(JSON.stringify(temp));
    fetch(`${Environment()}/orders/getOrdersById?orders=${temp}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setOrders(data);
      setForm(data);
      if(isFirstRun.current){
        if(data.length > 1){
          const locations = encodeURIComponent(JSON.stringify(data));
          const origin = encodeURIComponent(JSON.stringify(coordinates));
          fetch(`${Environment()}/orders/getDeliveryRoute?orders=${locations}&origin=${origin}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then(sortedOrders => sortedOrders.json())
          .then((sortedOrders) => {
            if(sortedOrders.routes[0]){
              setWaypointOrder(sortedOrders.routes[0].waypoint_order);
            }
            else{
              setError('No se pudo generar una ruta.');
            }
          })
          .catch(err => {
              console.error(err);
          });
        } else {
          setWaypointOrder([0])
        }

        fetch(`${Environment()}/orders/deliverOrders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({orders:data})
        })
        .catch(err => {
            console.error(err);
            setError('No se pudo notificar al cliente que su pedido está en camino.');
        });
      }
    })
    .catch(err => {
        console.error(err);
    });

  }, [postalCodes, coordinates, updatedProduct])

  useEffect(() => {
    if(orders.length > 0){
      const locations = encodeURIComponent(JSON.stringify((orders.map(element => element.idOrder))));
      fetch(`${Environment()}/orders/getProductOrders?orders=${locations}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        setProductOrders(data)
      })
      .catch(err => {
          console.error(err);
      });
    }
  }, [orders, updatedProduct])

  useEffect(() => {
    if(orders.length > 0 && waypointOrder.length > 0){
      setOrderIdx(waypointOrder[0]);
      setCenter({lat: parseFloat(orders[waypointOrder[0]].latitude), lng: parseFloat(orders[waypointOrder[0]].longitude)});
    }
  }, [waypointOrder, orders])

  useEffect(() => {
    if(productOrders && orderIdx !== null && form[orderIdx].idOrder && productOrders[form[orderIdx].idOrder]){
      setProductList(Object.keys(productOrders[form[orderIdx].idOrder]).map((product, index) => {
        const UM = Number(productOrders[form[orderIdx].idOrder][product].quantity) === 1 ? productOrders[form[orderIdx].idOrder][product].singularName : productOrders[form[orderIdx].idOrder][product].pluralName;
        return(
          <Form.Check 
            key={productOrders[form[orderIdx].idOrder][product].idProduct}
            label={`${productOrders[form[orderIdx].idOrder][product].quantity} ${UM} de ${productOrders[form[orderIdx].idOrder][product].name}`}
          />
        )
      }))
    } else {
      setProductList();
    }
  }, [productOrders, form, orderIdx])

  function CancelOrder(e){
    e.preventDefault();

    if(cancelCounter < 2){
      setCancelCounter(cancelCounter + 1)
      setTimeout(function(){
        setCancelCounter(0);
      }, 10000)
    } else if(cancelCounter === 2) {
        setCancelCounter(0)
        fetch(`${Environment()}/orders/cancelOrder`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({idOrder:form[orderIdx].idOrder, isCanceled:form[orderIdx].isCanceled, isReceived:orders[orderIdx].isReceived})
          })
          .then(() => {
            updateForm('isCanceled', !form[orderIdx].isCanceled)
          })
          .catch(err => {
              console.error(err);
              console.log(err)
          });
        }
  }


  function nextOrder(e){
    e.preventDefault();
    saveOrder();
    setCenter({lat: parseFloat(form[waypointOrder[tabNumber+1]].latitude), lng: parseFloat(form[waypointOrder[tabNumber+1]].longitude)});
    setOrderIdx(waypointOrder[tabNumber+1]);
    setTabNumber(tabNumber+1);
  }

  function previousOrder(e){
    e.preventDefault();
    setCenter({lat: parseFloat(form[waypointOrder[tabNumber-1]].latitude), lng: parseFloat(form[waypointOrder[tabNumber-1]].longitude)});
    setOrderIdx(waypointOrder[tabNumber-1]);
    setTabNumber(tabNumber-1);
  }

  const containerStyle = {
    height: '50vh',
    width: '100%'

  };
  
  function updateForm(attribute, value) {
    let temp = form;
    temp[orderIdx][attribute] = value;

    return setForm([...temp]);
  }

  function dragMarker(marker){
    updateForm('latitude', marker.latLng.lat());
    updateForm('longitude', marker.latLng.lng());
  }

  const handleCheckChange = () => { 
    updateForm('description', 'Pago de ' + form[orderIdx].firstName + ' ' + form[orderIdx].lastName);
    updateForm('deliveryDatetime', new Date());
    updateForm('amount', Array.from(productOrders[form[orderIdx].idOrder]).reduce((partialSum, product) => partialSum + ((product.soldPrice)*product.quantity), 0));
    updateForm('isReceived', !form[orderIdx]['isReceived']);
  };

  function saveOrder(){
    fetch(`${Environment()}/orders/modifyOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...form[orderIdx], isPa:form[orderIdx].form === "Pendiente" ? false : true})
    })
    .catch(err => {
        console.error(err);
    });
  }

  function HandlePayForm(e){
    updateForm('form', e.currentTarget.value);
    e.currentTarget.value === "Pendiente" ? updateForm('isPayed', false) : updateForm('isPayed', true)
  }

  return (
    <Container>
      <AdminBar></AdminBar>
      { (form.length < 1 || orderIdx === null) &&
        <Container className='Layout mb-5 text-center'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
        </Container>
      }
      { form.length > 0 && orderIdx !== null &&
      <Container className='Layout'>
        <Row className='mb-5'>
          <Col className='text-start d-grid gap-2'>
            <Button
            onClick={(e) => {previousOrder(e)}} 
            disabled={tabNumber === 0} 
            size="sm" variant='success'>
              <ion-icon name="arrow-back"/>
            </Button>
          </Col>
          <Col className='text-end d-grid gap-2'>
            <Button 
            onClick={(e) => {nextOrder(e)}} 
            disabled={tabNumber === form.length-1} 
            variant='success'>
              <ion-icon name="arrow-forward"/>
            </Button>
          </Col>
        </Row>
        <Row className='mb-3'>
        <h5 hidden={!simulation} className='text-danger'><b>Simulación de entrega</b></h5>
          <Col>
            <h5><b>Pedido <span className='green'>{tabNumber + 1} de {form.length}</span></b></h5>
          </Col>
          <Col className='text-end'>
            <h5><b>ID <span className='green'>{form[orderIdx].idOrder}</span></b></h5>
          </Col>
        </Row>
        <p className='text-danger'>
          {error}
        </p>
        <Card 
        className='shadow mb-3'>
          <Card.Header>
            <Row>
              <Col className='text-start'>
                <Button onClick={() => {window.open(`https://www.google.com/maps/dir/?api=1&destination=${form[orderIdx].latitude},${form[orderIdx].longitude}&travelmode=driving`)}} size="sm" variant='success'><ion-icon name="navigate"></ion-icon>Cómo llegar</Button>
              </Col>
              <Col className='text-end'>
                <Button href={`tel:${form[orderIdx].phone}`} size="sm" variant='success'><ion-icon name="call"></ion-icon> Llamar</Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Card.Title className='text-danger' hidden={!form[orderIdx].isCanceled}>Pedido cancelado</Card.Title>
            <Card.Title><b>Información del cliente</b></Card.Title>
            <Card.Text><ion-icon name="person-circle-outline"/> Nombre: {form[orderIdx].firstName} {form[orderIdx].lastName}</Card.Text>
            <Card.Text><ion-icon name="phone-portrait-outline"/> Teléfono: {form[orderIdx].phone}</Card.Text>
            <Card.Text><ion-icon name="home-outline"/> Dirección: {form[orderIdx].street} {form[orderIdx].externalNumber} {form[orderIdx].internalNumber}, {form[orderIdx].neighborhood}, {form[orderIdx].postalCode}</Card.Text>
            <Card.Text><ion-icon name="compass-outline"></ion-icon> Referencias</Card.Text>
            <Form.Control
              disabled={simulation}
              className='mb-3'
              as="textarea" rows={3}
              placeholder="Escribe aquí"
              value={form[orderIdx].referenceNotes? form[orderIdx].referenceNotes : ''}
              onChange={(e) => {updateForm('referenceNotes', e.currentTarget.value)}}
            />
            {!map &&
              <Container>
                <LoadScript id="script-loader" googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
                <GoogleMap
                    options={{
                    mapTypeControl: false,
                    }}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={18}
                >
                    <MarkerF
                      position={center}
                      draggable={true}
                      onDragEnd={(marker) => {dragMarker(marker)}}
                    />
                </GoogleMap>
                </LoadScript>
              </Container>
            }
            <Row>
              <Button disabled={simulation} onClick={() => {setMap(!map)}} size="sm" variant='success'><ion-icon name="location"/> Modificar ubicación</Button>
            </Row>
          </Card.Body>
        </Card>
        <Card
        className='shadow mb-3'>
          <Card.Header>
            <Row>
              <Col className='text-start'>
                <Button disabled={simulation} onClick={(e) => {setEditMode(!editMode)}} size="sm" variant='success'>
                  {!editMode && <small><ion-icon name="create-outline"/>Editar pedido</small>}
                  {editMode && <small><ion-icon name="checkmark-outline"/>Terminar</small>}
                </Button>
              </Col>
              <Col className='text-end'>
                <Button 
                  disabled={simulation || form[orderIdx].isReceived}
                  variant='danger' 
                  onClick={(e) => {CancelOrder(e)}} 
                  size="sm">
                  <small hidden={form[orderIdx].isCanceled}><ion-icon name="close-circle-outline"/> Cancelar pedido</small>
                  <small hidden={!form[orderIdx].isCanceled}><ion-icon name="close-circle-outline"/> Recuperar pedido</small>
                </Button>
                <small className='text-danger' hidden={cancelCounter<1}>{cancelCounter} / 3</small>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Card.Title className='text-danger' hidden={!form[orderIdx].isCanceled}>Pedido cancelado</Card.Title>
            <Card.Title><b>Productos a entregar</b></Card.Title>
            {productList}
            <div hidden={!editMode}>
              <ModifyClientOrder disabled={form[orderIdx].isReceived} updatedProduct={updatedProduct} setUpdatedProduct={setUpdatedProduct} idOrder={form[orderIdx].idOrder}/>
            </div>
          </Card.Body>
        </Card>
        <Card
        className='shadow mb-3'>
          <Card.Body>
            <Card.Title className='mb-3'><b>Registrar entrega</b></Card.Title>
            <p><b>Marcar el pedido como entregado</b></p>
            <Form.Check
              disabled={simulation || form[orderIdx].isCanceled}
              className='mb-3' 
              label='Entregado'
              checked={form[orderIdx].isReceived}
              onChange={handleCheckChange}
            />
          </Card.Body>
        </Card>
        <Card
        className='shadow mb-3'>
          <Card.Body>
            <Card.Title className='mb-3'><b>Registrar pago</b></Card.Title>
            <p><b>Total a pagar</b></p>
            {
              productOrders !== 0 && form[orderIdx].idOrder && 
              <h4>${Array.from(productOrders[form[orderIdx].idOrder]).reduce((partialSum, product) => partialSum + ((Number(product.soldPrice) - Number(product.soldDiscount)) * Number(product.quantity)), 0).toFixed(2)}</h4>
            }
            <Form.Label><b>Forma de pago</b></Form.Label>
            <Form.Select disabled={simulation} className='mb-3' value={form[orderIdx].form? form[orderIdx].form : 'Efectivo' } onChange={(e) => {HandlePayForm(e)}}>
              <option value="Efectivo">Efectivo</option>
              <option value="Transferencia">Transferencia</option>
              <option value="Tarjeta">Tarjeta</option>
              <option value="Pendiente">Pendiente</option>
            </Form.Select>
            <Form.Control
              disabled={simulation}
              className='mb-3'
              as="textarea" rows={3}
              placeholder="Notas"
              value={form[orderIdx].notes? form[orderIdx].notes : ''}
              onChange={(e) => {updateForm('notes',e.currentTarget.value)}}
            />
          </Card.Body>
        </Card>
          <Row>
            { orderIdx === orders.length - 1 &&
            <Col>
              <Button href='/administracion' onClick={() => {saveOrder()}} className='mb-3' variant='success'>Finalizar entrega</Button>
            </Col>
            }
            { orderIdx !== orders.length - 1 &&
              <Col className='text-start d-grid gap-2'>
                <Button
                onClick={(e) => {previousOrder(e)}} 
                disabled={tabNumber === 0} 
                size="sm" variant='success'>
                  <ion-icon name="arrow-back"/>
                </Button>
              </Col>
              &&
              <Col className='text-end d-grid gap-2'>
                <Button 
                onClick={(e) => {nextOrder(e)}} 
                disabled={tabNumber === form.length-1} 
                variant='success'>
                  <ion-icon name="arrow-forward"/>
                </Button>
              </Col>
            }
          </Row>
      </Container>}
    </Container>
  );
}

export default OrderDelivery;