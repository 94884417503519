import React, { useState, useEffect } from 'react';
import NavbarUser from '../components/navbar';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FooterUser from '../components/footerUser';
import Ratio from 'react-bootstrap/Ratio';
import Environment from './Environment';
import ModifyPostModal from '../components/modifyPostModal';
import DeletePostModal from '../components/deletePostModal';
import { useParams } from 'react-router-dom';


function Post() {

    let { idPost } = useParams();
    const [post, setPost] = useState({});
    const [user, setUser] = useState({});
    const [modalShowModify, setModalShowModify] = useState(false);
    const [modalShowDelete, setModalShowDelete] = useState(false);

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(() => {
        let video = '';

        if (post.video){
            video = post.video.split('/');
            if(video.length === 4){
                video = video[3].split('?')[0]
            } else {
                video  = '';
            }
        }

    }, [post])

    const VideoFrame = (() => {
        let video = '';

        if (post.video){
            video = post.video.split('/');
            if(video.length === 4){
                video = video[3].split('?')[0]
            } else {
                video  = '';
            }
        }

        return(
            <iframe crossorigin="anonymous" className='mb-5' src={`https://www.youtube-nocookie.com/embed/${video}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        )
        
    });    

    useEffect(() => {
        fetch(`${Environment()}/login/isLoggedIn`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(session => session.json())
        .then((session) => {
            setUser(session);
        })
    }, [])

    useEffect(() => {
        fetch(`${Environment()}/posts/getPostById/${idPost}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setPost(data[0]);
        })
        .catch(err => {
            console.error(err);
        });
    }, [idPost])

    return (
        <Container>
            <NavbarUser active='/AboutUs'></NavbarUser>
            <Container className='Layout'>
            <Row>
                <Col>
                    <h3 className='mb-2'><b>{post.title}</b></h3>
                    <p className='text-muted mb-5'>{new Date(post.dateTime).toLocaleDateString('es-MX', options)}</p>
                </Col>
                {   
                    user.isLoggedIn && (user.role === 'Coordinación' || user.role === 'Gerencia') &&
                    <Col className='text-end'>
                        <Button className='me-3' onClick={() => setModalShowModify(true)} variant='outline-success'><ion-icon name="create-outline"/></Button>
                        <Button onClick={(e) => setModalShowDelete(true)} variant="outline-danger"><ion-icon name="trash-outline"></ion-icon></Button>
                    </Col>
                }
            </Row>
            { post.image &&
                <Image className='mb-5' height="315" src={`${Environment()}/post${post.idPost}${post.imgExtension}`}></Image>
            }
            { post.video &&
                <Row>
                    <Col>
                        <Ratio aspectRatio='16x9'>
                            <VideoFrame/>
                        </Ratio>
                    </Col>
                </Row>
            }
            <p className='justify mt-5'>{post.description}</p>
            </Container>
            <FooterUser active='/AboutUs'></FooterUser>
            <ModifyPostModal
            show={modalShowModify}
            onHide={() => setModalShowModify(false)}
            title='Modificar post'
            description=''
            post={post}
            />
            <DeletePostModal
            show={modalShowDelete}
            onHide={() => setModalShowDelete(false)}
            id={idPost}
            />
        </Container>
        
    );
}

export default Post;
