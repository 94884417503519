import Environment from './../views/Environment';


function Logout(){
    fetch(`${Environment()}/login/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
    })
    .then(() => {
        window.location.href = '/inicio';
    })
    .catch(err => {
        console.error(err);
        return "Ocurrió un error. Inténtalo más tarde.";
    });

    return '';
}

export default Logout;