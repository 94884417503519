import { Navigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

import Environment from './../views/Environment';

export const ProtectedRoute = ({ authorizedRoles, children }) => {

    const [session, setSession] = useState(null);

    useEffect(() => { 
        async function isLoggedIn() {
            const response = await fetch(`${Environment()}/login/isLoggedIn`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            response.json()
            .then((data) => {
                setSession(data);
            })
            .catch(err => {
                console.log(err);
            }); 
        }

        isLoggedIn();
    }, [])
    
    if (session === null) {
        return null;
    }
    else if (!session.role) {
        return <Navigate to="/login" replace />;
    }
    else if (session.role === 'Punto de venta' || session.role === 'Cliente'){
        return <Navigate to="/inicio" state={{role: session.role}} replace />;
    }
    else if (!authorizedRoles.includes(session.role)){
        return <Navigate to="/deniedaccesspage" replace />;
    }
    return children;
};