import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/esm/Container';
import AdminBar from '../components/AdminBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Environment from './Environment';
import './views.css';

function BalanceSheet() {

  const [balance, setBalance] = useState(new Array(24).fill(0));
  const [accountsReceivable, setAccountsReceivable] = useState(0);
  const [inventory, setInventory] = useState(0);
  const [accountsPayable, setAccountsPayable] = useState(0);

  const [currentAssets, setCurrentAssets] = useState(0);
  const [fixedAssets, setFixedAssets] = useState(0);
  const [assets, setAssets] = useState(0);
  const [currentPassive, setCurrentPassive] = useState(0);
  const [fixedPassive, setFixedPassive] = useState(0);
  const [passive, setPassive] = useState(0);
  const [patrimony, setPatrimony] = useState(0);
  const [patrimonyPassive, setPatrimonyPassive] = useState(0);

  function updateBalance(index, value) {

    let temp = [...balance]
    temp[index] = value; 
    temp = temp.map(i => i !== ''? parseFloat(i) : 0);

    setCurrentAssets(temp[0]+temp[1]+accountsReceivable+inventory);
    setFixedAssets(temp[5]+temp[6]+temp[7]);
    setAssets(temp[4]+temp[8]+temp[9]);
    setCurrentPassive(accountsPayable+temp[12]+temp[13]);
    setFixedPassive(temp[15]+temp[16]);
    setPassive(temp[14]+temp[17]);
    setPatrimony(temp[19]+temp[20]+temp[21]);
    setPatrimonyPassive(temp[18]+temp[22]);

    temp[index] = value;
    setBalance([...temp]);
  }

  useEffect(() => {

    let temp = [...balance];
    temp = temp.map(i => i !== ''? parseFloat(i) : 0);
    setCurrentAssets(temp[0]+temp[1]+accountsReceivable+inventory);
    setFixedAssets(temp[5]+temp[6]+temp[7]);
    setAssets(currentAssets + fixedAssets + temp[9]);
    setCurrentPassive(accountsPayable+temp[12]+temp[13]);
    setFixedPassive(temp[15]+temp[16]);
    setPassive(currentPassive + fixedPassive);
    setPatrimony(temp[19]+temp[20]+temp[21]);
    setPatrimonyPassive(patrimony + passive);
  }, [accountsReceivable, inventory, accountsPayable, balance, currentAssets, fixedAssets, currentPassive, fixedPassive, passive, patrimony])


  useEffect(() => {
    fetch(`${Environment()}/supplierOrder/getInventory`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      const total = Array.from(data).reduce((partialSum, product) => partialSum + ((product.cost)*product.quantity), 0);
      setInventory(total.toFixed(2));
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/orders/accountsReceivable`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      if(data.length > 0){
        setAccountsReceivable(data[0].accountsReceivable);
      }
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/supplierOrder/accountsPayable`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      if(data){
        setAccountsPayable(data.accountsPayable);
      }
    })
    .catch(err => {
        console.error(err);
    });
  }, [])


  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout'>
        <h3 className='mb-5'><b>Balance <span className='green'>general</span></b></h3>
          <div className='mb-5'>
            <h4 className='mb-3'><b>Activos</b></h4>
            <h5 className='mb-3'><b>Activo circulante</b></h5>
            <Row className='mb-3'>
              <Col>
                <p>Caja chica</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[0]}
                onInput={(e) => {updateBalance(0, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Banco</p>
              </Col>
              <Col>
                <Form.Control
                  type='number'
                  value={balance[1]}
                  onInput={(e) => {updateBalance(1, e.currentTarget.value)}}
                  />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Cuentas por cobrar</p>
              </Col>
              <Col>
                <InputGroup className="mb-3">
                  <Form.Control
                  disabled
                  type='number'
                  value={accountsReceivable}
                  onInput={(e) => {setAccountsReceivable(e.currentTarget.value)}}
                  />
                  <Button href='/pedidos' variant='success'>Ir a pedidos</Button>
                </InputGroup>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Inventario</p>
              </Col>
              <Col>
                <InputGroup className="mb-3">
                  <Form.Control
                  disabled
                  type='number'
                  value={inventory}
                  onInput={(e) => {setInventory(e.currentTarget.value)}}
                  />
                  <Button href='/inventario' variant='success'>Ir al inventario</Button>
                </InputGroup>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p><b>Total activo circulante</b></p>
              </Col>
              <Col>
                <Form.Control
                disabled
                type='number'
                value={currentAssets}
                onInput={(e) => {setCurrentAssets(4, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <h5 className='mb-3'><b>Activo fijo</b></h5>
            <Row className='mb-3'>
              <Col>
                <p>Propiedades</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[5]}
                onInput={(e) => {updateBalance(5, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Vehículos</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[6]}
                onInput={(e) => {updateBalance(6, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Maquinaria</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[7]}
                onInput={(e) => {updateBalance(7, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p><b>Total activo fijo</b></p>
              </Col>
              <Col>
                <Form.Control
                disabled
                type='number'
                value={fixedAssets}
                onInput={(e) => {setFixedAssets(8, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Otros activos</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[9]}
                onInput={(e) => {updateBalance(9, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p><b>Total activos</b></p>
              </Col>
              <Col>
                <Form.Control
                disabled
                type='number'
                value={assets}
                onInput={(e) => {setAssets(10, e.currentTarget.value)}}
                />
              </Col>
            </Row>
          </div>
          <div className='mb-5'>
            <h4 className='mb-3'><b>Pasivos</b></h4>
            <h5 className='mb-3'><b>Pasivo circulante (deudas de menos de un año)</b></h5>
            <Row className='mb-3'>
              <Col>
                <p>Cuentas por pagar a proveedores de alimentos</p>
              </Col>
              <Col>
                <InputGroup className="mb-3">
                  <Form.Control
                  disabled
                  type='number'
                  value={accountsPayable}
                  onInput={(e) => {setAccountsPayable(e.currentTarget.value)}}
                  />
                  <Button href='/pedidosproveedores' variant='success'>Ir a pedidos proveedores</Button>
                </InputGroup>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Cuentas por pagar a otros proveedores</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[12]}
                onInput={(e) => {updateBalance(12, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Pago a empleados</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[13]}
                onInput={(e) => {updateBalance(13, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p><b>Total pasivos circulantes</b></p>
              </Col>
              <Col>
                <Form.Control
                disabled
                type='number'
                value={currentPassive}
                onInput={(e) => {setCurrentPassive(14, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <h5 className='mb-3'><b>Pasivo fijo (deudas de más de un año)</b></h5>
            <Row className='mb-3'>
              <Col>
                <p>Créditos bancarios</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[15]}
                onInput={(e) => {updateBalance(15, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Obligaciones tributarias</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[16]}
                onInput={(e) => {updateBalance(16, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p><b>Total pasivos fijos</b></p>
              </Col>
              <Col>
                <Form.Control
                disabled
                type='number'
                value={fixedPassive}
                onInput={(e) => {setFixedPassive(17, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p><b>Total pasivos</b></p>
              </Col>
              <Col>
                <Form.Control
                disabled
                type='number'
                value={passive}
                onInput={(e) => {setPassive(18, e.currentTarget.value)}}
                />
              </Col>
            </Row>
          </div>
          <div className='mb-5'>
            <h4 className='mb-3'><b>Patrimonio</b></h4>
            <Row className='mb-3'>
              <Col>
                <p>Aportes sociales</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[19]}
                onInput={(e) => {updateBalance(19, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Utilidades retenidas</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[20]}
                onInput={(e) => {updateBalance(20, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p>Superavit</p>
              </Col>
              <Col>
                <Form.Control
                type='number'
                value={balance[21]}
                onInput={(e) => {updateBalance(21, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p><b>Total patrimonio</b></p>
              </Col>
              <Col>
                <Form.Control
                disabled
                type='number'
                value={patrimony}
                onInput={(e) => {setPatrimony(22, e.currentTarget.value)}}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <p><b>Total pasivo + patrimonio</b></p>
              </Col>
              <Col>
                <Form.Control
                disabled
                type='number'
                value={patrimonyPassive}
                onInput={(e) => {setPatrimonyPassive(23, e.currentTarget.value)}}
                />
              </Col>
            </Row>
          </div>
          <div className='text-end mb-5'>
            <h5>¿Cómo llenar un balance general?</h5>
            <iframe crossorigin="anonymous" width="560" height="315" src="https://www.youtube-nocookie.com/embed/8i3OXzkr5Nk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
      </Container>
    </Container>
  );
}

export default BalanceSheet;