import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Environment from '../views/Environment';
import './components.css';

function NewCategoryModal(props) {

  const [formError, setFormError] = useState('');
  const [newCategory, setNewCategory] = useState(
    {
      name: '',
      shortDescription: '',
      image: ''
    }
  );

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setNewCategory((prev) => ({ ...prev, ...value }));
  }

   /**
   * onSubmit
   * @description Posts package product through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(newCategory.image.type !== "image/png" && newCategory.image.type !== "image/jpg" && newCategory.image.type !== "image/jpeg"){
        setFormError("La imagen adjuntada no es png, jpg o jpeg.")
        return;
      }

      const formData = new FormData();
      formData.append('image', newCategory.image);
      formData.append('name', newCategory.name);
      formData.append('shortDescription', newCategory.shortDescription);

      fetch(`${Environment()}/categories/getCategory/${newCategory.name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        if(data.length){
          setFormError("El nombre de categoría ya existe");
          return;
        }
        else{
          fetch(`${Environment()}/categories/postCategory`, {
            method: 'POST',
            body: formData
          });
          
          setNewCategory(
            {
              name: '',
              shortDescription: '',
              image: ''
            }
          );
            
          window.location.reload();
        }
      })
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputCategoryName">Nombre</Form.Label>
              <Form.Control
                required
                type="text"
                id="inputCategoryName"
                aria-describedby="categoryName"
                placeholder="Verduras"
                onChange={(e) => updateForm({ name: e.target.value })}
                value={newCategory.name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputCategoryDesc">Descripción corta</Form.Label>
              <Form.Control
                required
                type="text"
                id="inputCategoryDesc"
                aria-describedby="categoryDescription"
                placeholder="Hortalizas, bulbos, raíces y más"
                onChange={(e) => updateForm({ shortDescription: e.target.value })}
                value={newCategory.shortDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputCategoryImage">Imagen</Form.Label>
              <Form.Control 
                required
                type='file'
                id='inputImage'
                aria-describedby="categoryImage"
                onChange={(e) => updateForm({ image: e.target.files[0] })}
              />
              <Form.Text>Selecciona una imagen png, jpg o jpeg.</Form.Text>
            </Form.Group>
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewCategoryModal;