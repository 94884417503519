import './components.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Environment from '../views/Environment';

function HideProductModal(props) {
  const action = (props.action === 1 ? 'Mostrar' : 'Ocultar');

  function HideProduct(e){
    const idProduct = props.id;
    
    fetch(`${Environment()}/products/hideProduct`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({idProduct:idProduct, isHidden:props.action})
    })
    .then(() => {
        window.location.reload();
    })
    .catch(err => {
        console.error(err);
    });
}

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {action} producto
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          ¿Estás seguro de que quieres {action} este producto?
        </p>
        <p className='text-danger'>
          {props.name}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onHide}>Cancelar</Button>
        <Button variant='primary' onClick={(e) => HideProduct(e)}>{action}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HideProductModal;