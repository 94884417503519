import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from 'react-bootstrap/Accordion';
import Environment from '../views/Environment';
import './components.css';

function DeliveredGroupedOrders(props){
    const [groups, setGroup] = useState([]);

    useEffect(() => {
        fetch(`${Environment()}/orders/getDeliveredOrders/${props.date}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setGroup(data);
        })
        .catch(err => {
            console.error(err);
        });
    }, [props])

    return(
        <Container>
            <Accordion>
                {Object.keys(groups).map((group, index) => {
                    return(
                        <Accordion.Item
                        bg={'light'} 
                        text={'black'}
                        key={group}
                        eventKey={index}
                        className='mb-3'>
                            <Accordion.Header>
                                <small>Código postal: <b>{group}</b></small> 
                            </Accordion.Header>
                            <Accordion.Body>
                                {Object.keys(groups[group]).map((order, index) => {
                                    return(
                                        <ListGroup className='mb-4' key={groups[group][order].idOrder}>
                                            <ListGroup.Item action variant="success" className='text-success'><small><b>ID: {groups[group][order].idOrder}</b></small><b></b></ListGroup.Item>
                                            <ListGroup.Item><small>Cliente: {groups[group][order].firstName} {groups[group][order].lastName}</small></ListGroup.Item>
                                            <ListGroup.Item><small>Cliente: {groups[group][order].firstName} {groups[group][order].neighborhood}</small></ListGroup.Item>
                                        </ListGroup>
                                    );
                                })}
                            </Accordion.Body>
                        </Accordion.Item>              
                    )
                })}  
            </Accordion>
        </Container>
    );
}

export default DeliveredGroupedOrders;