import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Environment from '../views/Environment';
import './components.css';

function FooterUser(props) {

    const [form, setForm] = useState({});
    const [active, setActive] = useState(props.active);

    useEffect(() => {
        async function isLoggedIn() {
            const response = await fetch(`${Environment()}/login/isLoggedIn`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            response.json()
            .then((data) => {
                setForm(data);
            })
            .then(() => {
                const activeLink = document.getElementById(props.active)
                activeLink.style.color = 'green';
                activeLink.style.fontWeight = 'bold';
            })
            .catch(err => {
                console.log(err);
            }); 
        }

        isLoggedIn();

      }, [props]);

    const handleSelect = (eventKey) => setActive(eventKey);

    return (
            <Navbar className='d-lg-none border bg-white' fixed="bottom">
                <Container fluid> 
                    <Nav
                        style={{ maxHeight: '100px' }}
                        activeKey={active}
                        onSelect={handleSelect}
                        className="mx-auto text-center"
                    >
                        <Nav.Item>
                            <Nav.Link className="flex-column" id="/inicio" href="/inicio">
                                    <ion-icon slot="start" name="home"/><br />
                                    <small>Inicio</small>
                            </Nav.Link>
                        </Nav.Item> 

                        <Nav.Item>
                            <Nav.Link className="flex-column" id="/productos" eventKey="/productos" href="/productos">
                                    <ion-icon slot="start" name="storefront"/><br />
                                    <small>Tienda</small>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link className="flex-column" id="/AboutUs" href="/AboutUs">
                                    <ion-icon slot="start" name="people"/><br />
                                    <small>Nosotros</small>
                            </Nav.Link>
                        </Nav.Item>

                        {!form.isLoggedIn && 
                            <Nav.Item >
                                <Nav.Link className="flex-column" id="/login" href="/login">
                                        <ion-icon slot="start" name="person-circle"/><br />
                                        <small>Cuenta</small>
                                </Nav.Link>
                            </Nav.Item>
                        }

                        {form.isLoggedIn &&
                            <Nav.Item>
                                <Nav.Link className="flex-column" id="/misPedidos" href="/misPedidos">
                                        <ion-icon slot="start" name="bag-handle"/><br />
                                        <small>Pedidos</small>
                                </Nav.Link>
                            </Nav.Item>
                        }

                        {form.isLoggedIn &&
                            <Nav.Item>
                                <Nav.Link className="flex-column" id="/micuenta" href="/micuenta">
                                        <ion-icon slot="start" name="person-circle"/><br />
                                        <small>Cuenta</small>
                                </Nav.Link>
                            </Nav.Item>
                        }
                    </Nav>                      
                </Container>
            </Navbar>
    );
}

export default FooterUser;