import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Autocomplete, MarkerF } from '@react-google-maps/api';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import './../components/components.css';
import './views.css'
import Environment from './Environment';
import AdminBar from '../components/AdminBar';
const libraries = ["places"];

function RegisterUser() {
  const [autocomplete, setAutocomplete] = useState(null);

  const [form, setForm] = useState({
      phoneCode: '52',
      internalNumber: '',
      latitude: 0,
      longitude: 0,
      firstName: '',
      lastName: '',
      email: '',
      street: '',
      externalNumber: '',
      neighborhood: '',
      postalCode: '',
      phone: '',
      password: '',
      role: 'SignOnLess'
  });

  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [success, setSuccess] = useState('');

  function phoneValidation() {
    const regex = /^\d{10}$/
    if(!regex.test(form.phone)){
      setError('El número telefónico no contiene 10 dígitos numéricos.');
      return false;
    }
    return true;
  }


  function locationValidation(){
    if(form.latitude !== 0 && form.longitude !== 0){
      return true;
    }
    else{
      setError('Selecciona una ubicación exacta.')
      return false;
    }
  }


  /**
   * onSubmit
   * @description Posts the auth data to the api and receives the status
   * @param e: Context
   */
  async function onSubmit(e) {
    e.preventDefault()
    
    if(phoneValidation() && locationValidation()){
      fetch(`${Environment()}/login/register`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(form),
      })
      .then((response) => {
        if(response.status === 200){
          setSuccess('Cliente creado exitosamente.')
          setTimeout(function () {
            navigate('/gestionarusuarios');
          }, 3000);
        }
      })
      .catch(err => {
        console.error(err);
        setError("En este momento no se puede registrar un cliente. Inténtalo más tarde.");
      });
    }
  }

  /**
  * updateForm
  * @description updates data of a form
  * @param value: new values of the form
  * @returns an updated form
  */
  function updateForm(value) {
      return setForm((prev) => ({ ...prev, ...value }));
  }

  const containerStyle = {
    height: '50vh',
    width: '100%'

  };

  const [position, setPosition] = useState({
    lat: 0,
    lng: 0
  });

  const [center, setCenter] = useState({
    lat: 20.5931,
    lng: -100.392
  });

  function onLoad (e) {
    setAutocomplete(e);
  }

  function onPlaceChanged (e) {
    if (autocomplete !== null) {
      setCenter(autocomplete.getPlace().geometry.location)
      setPosition(autocomplete.getPlace().geometry.location)
      setForm({...form, latitude: autocomplete.getPlace().geometry.location.lat(), longitude: autocomplete.getPlace().geometry.location.lng() });
    }
  }

  function dragMarker(marker){
    setForm({...form, latitude: marker.latLng.lat(), longitude: marker.latLng.lng()});
  }

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="position-relative"
      style={{ minHeight: '240px' }}
    >
      <AdminBar/>
      <Container className='Layout mb-5'>
        <Row className='d-flex align-items-center justify-content-center'>
          <Col md={8}>
            <h3><b>Crear <span className='green'>cliente</span></b></h3>
            <Card className='mt-3 mb-5 shadow LoginCard'>
              <Form onSubmit={onSubmit} id="registerForm">
                <Card.Body autoComplete="new-password">
                  <Form.Group className="mb-3">
                    <Form.Label><b>Nombre</b></Form.Label>
                    <Form.Control 
                      required 
                      type="text" 
                      maxLength={32} 
                      placeholder="Alejandro" 
                      onChange={(e) => updateForm({ firstName: e.target.value })}
                      value={form.firstName}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label><b>Apellidos</b></Form.Label>
                    <Form.Control 
                      required type="text" 
                      maxLength={32} 
                      placeholder="Rodríguez" 
                      onChange={(e) => updateForm({ lastName: e.target.value })} 
                      value={form.lastName}
                    />
                  </Form.Group>
                  <h6><b>Dirección</b></h6>
                  <p className='text-muted'>Por ahora sólo tenemos envíos a la ciudad de Querétaro.</p>
                  <Form.Group className="mb-3">
                    <Form.Label>Calle</Form.Label>
                    <Form.Control 
                      required
                      type="text"
                      placeholder="Saturno" 
                      maxLength={64} 
                      onChange={(e) => updateForm({ street: e.target.value })}
                      value={form.street}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Número exterior</Form.Label>
                    <Form.Control 
                      required
                      type="text"
                      maxLength={32}
                      placeholder="36B"
                      onChange={(e) => updateForm({ externalNumber: e.target.value })}
                      value={form.externalNumber}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Número interior (opcional)</Form.Label>
                    <Form.Control 
                      type="text"  
                      maxLength={32}
                      placeholder="7"
                      onChange={(e) => updateForm({ internalNumber: e.target.value })}
                      onKeyUp={(e) => updateForm({ internalNumber: e.target.value })}
                      value={form.internalNumber}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Colonia o fraccionamiento</Form.Label>
                    <Form.Control
                      required 
                      type="text"  
                      maxLength={64}
                      placeholder="El Campanario"
                      onChange={(e) => updateForm({ neighborhood: e.target.value })}
                      value={form.neighborhood}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Código postal</Form.Label>
                    <Form.Control
                      required 
                      type="text"  
                      maxLength={5}
                      minLength={5}
                      placeholder="76146"
                      onChange={(e) => updateForm({ postalCode: e.target.value })}
                      value={form.postalCode}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label><b>Ubicación exacta</b></Form.Label>
                    <br />
                    <Form.Text muted>
                      Busca tu dirección exacta en la barra de búsqueda y ajusta el marcador para mayor precisión.
                    </Form.Text>
                  </Form.Group>
                  <LoadScript id="script-loader" googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
                    <GoogleMap
                      options={{
                        mapTypeControl: false,
                      }}
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={18}
                    >
                      <Autocomplete
                        autoComplete="off"
                        onLoad={(e) => {onLoad(e)}}
                        onPlaceChanged={(e) => {onPlaceChanged(e)}}
                      >
                        <Form.Group className="mb-3">
                          <Form.Control 
                            type="text"
                            required
                            placeholder="Buscar dirección"
                            style={{
                              boxSizing: `border-box`,
                              border: `1px solid transparent`,
                              width:'80%',
                              height: `32px`,
                              padding: `0 12px`,
                              borderRadius: `3px`,
                              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                              fontSize: `14px`,
                              outline: `none`,
                              textOverflow: `ellipses`,
                              position: "absolute",
                              left: "5%",
                            }}
                            />
                        </Form.Group>
                      </Autocomplete>
                      <MarkerF
                        position={position}
                        draggable={true}
                        onDrag={(marker) => {dragMarker(marker)}}
                      />
                    </GoogleMap>
                  </LoadScript>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>Teléfono</Form.Label>
                    <InputGroup>
                      <DropdownButton
                        variant="outline-secondary"
                        title={form.phoneCode}
                        align="end"
                        required
                      >
                        <Dropdown.Item onClick={(e) => updateForm({ phoneCode: '1' })}>+1</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => updateForm({ phoneCode: '52' })}>+52</Dropdown.Item>
                      </DropdownButton>
                      <Form.Control 
                        required 
                        type="tel" 
                        maxLength={10}
                        onChange={(e) => updateForm({ phone: e.target.value })}
                        value={form.phone}
                      />
                    </InputGroup>
                  </Form.Group>
                  <p className="text-danger">{error}</p>
                  <br/>
                </Card.Body>
                <Card.Footer className="text-muted d-grid gap-2">
                  <Button className='PrimaryBtn' type='submit'>Crear cliente</Button>
                  <Alert hidden={success? false : true} variant='success'>{success}</Alert>
                </Card.Footer>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RegisterUser;