import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from 'react-bootstrap/Accordion';
import Environment from '../views/Environment';
import './components.css';
const libraries = ["places"];

function GroupedOrders(props){
    const [groups, setGroup] = useState([]);
    const [buttonClicked, setIsButtonClicked] = useState(false);
    const [buttonSimulationClicked, setIsButtonSimulationClicked] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetch(`${Environment()}/orders/getPendingOrders/${props.date}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            const keys = Object.keys(data);
            keys.forEach(group => {
                data[group]['status'] = (props.checked === 'true' ? true : false);
            });
            setGroup(data);
        })
        .catch(err => {
            console.error(err);
        });
    }, [props])


    function selectAllGroups(e){
        const isChecked = e.currentTarget.checked;
        window.location.href = ('/entregarProductos/' + isChecked);
    }

    function selectedCheck(e){
        const isChecked = e.target.checked;
        const group = e.target.id;
        const temp = groups;
        temp[group]['status'] = isChecked;
        setGroup({...temp});
    }

    const containerStyle = {
        height: '50vh',
        width: '100%'
    
    };
    
    const [coordinates, setCoordinates] = useState({
        lat: 0,
        lng: 0
    });

    const [center, setCenter] = useState({
        lat: 20.5931,
        lng: -100.392
    });

    useEffect(() => {
        if (navigator.geolocation) {    
            navigator.geolocation.getCurrentPosition(setPosition, setError('Permite que la página acceda a tu ubicación y refréscala para continuar.'));
        }
        else { 
            setError('Este dispositivo no soporta geolocalización.');
        }

        function setPosition(position) {
            const lat = (position.coords.latitude);
            const lng = (position.coords.longitude);
            setCenter({lat: lat, lng: lng});
            setCoordinates({lat: lat, lng: lng});
            if(validateQuantity() === false){
                setError('Selecciona por lo menos un código postal.');
            }
            else{
                setError('')
            }
        }

        function validateQuantity(){
            const count = (Object.keys(groups).filter((group) => groups[group]['status'] === true).length);
            return count > 0;
        }
    }, [groups])

    return(
        <Container>
            <Card 
            bg={'light'} 
            text={'black'}
            className='mt-3 mb-3'>
                <Card.Body>
                    <Form.Check
                    inline
                    label={'Todos'}
                    onChange={(e) => {selectAllGroups(e)}}
                    defaultChecked={(props.checked === 'true' ? true : false)}
                    />
                </Card.Body>
            </Card>   
            <Accordion>
            {Object.keys(groups).map((group, index) => {
                return(
                    <Accordion.Item 
                    bg={'light'} 
                    text={'black'}
                    key={group}
                    eventKey={index}
                    className='mb-3'>
                        <Accordion.Header>
                            <Form.Check
                            inline
                            defaultChecked={groups[group]['status']}
                            id={group}
                            onChange={(e) => {selectedCheck(e)}}
                            />
                            <small>Código postal: <b>{group}</b></small> 
                        </Accordion.Header>
                        <Accordion.Body>
                            {Object.keys(groups[group]['orders']).map((order, index) => {
                                return(
                                    <ListGroup className='mb-4' key={groups[group]['orders'][order].idOrder}>
                                        <ListGroup.Item action variant="success" className='text-success'><small><b>ID: {groups[group]['orders'][order].idOrder}</b></small></ListGroup.Item>
                                        <ListGroup.Item><small>Cliente: {groups[group]['orders'][order].firstName} {groups[group]['orders'][order].lastName}</small></ListGroup.Item>
                                        <ListGroup.Item><small>Zona: {groups[group]['orders'][order].neighborhood}</small></ListGroup.Item>
                                    </ListGroup>
                                );
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                )
            })}
            </Accordion> 
            <Form.Group className="mb-3">
            <Form.Label><b>Ubicación exacta de partida</b></Form.Label>
            </Form.Group>
            <LoadScript id="script-loader" googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
            <GoogleMap
                options={{
                mapTypeControl: false,
                }}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
            >
                <MarkerF
                position={coordinates}
                draggable={true}
                />
            </GoogleMap>
            </LoadScript>                
            <Container className='text-center mt-4'>
                <Alert hidden={error === '' ? true : false} variant='danger'>{error}</Alert>
                <Row>
                    <Col>
                        <Button disabled={error === '' ? false : true} onClick={() => {setIsButtonClicked(true)}} variant='success'><small>Comenzar repartición</small></Button>
                    </Col>
                    <Col>
                        <Button disabled={error === '' ? false : true} onClick={() => {setIsButtonSimulationClicked(true)}} variant='success'><small>Simular repartición</small></Button>
                    </Col>
                </Row>

                {   
                    buttonClicked && coordinates.lat !== 0 && coordinates.lng !== 0 &&
                    <Navigate to="/entregarPedido" state={{ postalCodes: groups, coordinates:coordinates, simulation:false}} replace={true} />
                }
                {   
                    buttonSimulationClicked && coordinates.lat !== 0 && coordinates.lng !== 0 &&
                    <Navigate to="/entregarPedido" state={{ postalCodes: groups, coordinates:coordinates, simulation:true}} replace={true} />
                }
            </Container>
        </Container>
    );
}

export default GroupedOrders;