import Container from 'react-bootstrap/esm/Container';
import Image from 'react-bootstrap/esm/Image';
import UpsDog from './../images/UpsDog.jpg'
import './views.css'

function DeniedAccess() {
  return (
    <Container>
      <Container className='Layout text-center'>
        <h2>Ups! No tienes permiso para acceder a esta página.</h2>
        <Image width={500} src={UpsDog}></Image>
      </Container>
    </Container>
  );
}

export default DeniedAccess;
