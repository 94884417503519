import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import Carousel from 'react-bootstrap/Carousel';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
 import Environment from '../views/Environment';
import './components.css';


function Categories() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch(`${Environment()}/categories/getCategories`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setCategories(data);
        })
        .catch(err => {
            console.error(err);
        });
    }, [])

    let categorySize = categories.length;
    let numOfSlides = Math.ceil(categorySize/4);
    const [currentSlide, setCurrentSlide] = useState(1);
    let startIndex = (currentSlide - 1) * 4;
    let endIndex = startIndex + 4;
    let subset = categories.slice(startIndex, endIndex)

    function Next(e){
        if(currentSlide < numOfSlides){
            setCurrentSlide(currentSlide + 1);
        }
    }

    function Previous(e){
        if(currentSlide > 1){
            setCurrentSlide(currentSlide - 1);
        }
    }

    const cards = subset.map((category) =>
        <Col key={category.idCategory} className='d-none d-lg-block g-4 text-center' xs={5}>
            <a href={`productos/${category.idCategory}`} className="text-decoration-none transparent text-white">
                <Image className="card-img-top-cat" src={`${Environment()}/category${category.idCategory}${category.imgExtension}`} alt={category.name} />
                {category.name}
            </a>
        </Col>
    );

    const carousel = subset.map((category) =>
        <Carousel.Item key={category.idCategory}>
            <Stack
            direction="vertical"
            className='justify-content-center align-items-center text-center'
            gap={2}
          >
            <a href={`productos/${category.idCategory}`}>
                <Image

                    className="rounded ImageDown"
                    height={100}
                    width='auto'
                    src={`${Environment()}/category${category.idCategory}${category.imgExtension}`}
                    alt={category.name}
                /> 
                <h4 className='mb-5'><b>{category.name}</b></h4>
            </a>

          </Stack>
        </Carousel.Item>
    );

    return (
        <div className='background-trees outer-container'>
            <div className='background-inner-trees outer-container'>
                <Row className='outer-container'>
                    <Row className='justify-content-md-end'>
                        <Col className='d-none d-lg-block text-center'>
                            <h3 className='text-white'><b>Comprar por categoría</b></h3>
                        </Col>
                        <Col className='d-none d-lg-block' md="auto">
                            <Row className='justify-content-md-end'>
                                <Col xs="hidden" md="auto">
                                    <Button size='sm' onClick={(e) => {Previous(e)}} className='SecondaryBtn me-1'><ion-icon name="arrow-back-outline"></ion-icon></Button>
                                    <Button size='sm' onClick={(e) => {Next(e)}} className='SecondaryBtn'><ion-icon name="arrow-forward-outline"></ion-icon></Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mt-3 w-50'>
                            {cards}
                    </Row>
                    <Row className='d-block d-lg-none mt-3 mb-5'>
                        <h3 className='text-white'><b>Comprar por categoría</b></h3>
                        <Col className="CarouselBg" >
                            <Carousel className='border rounded shadow pb-3 pt-5' variant='dark'>
                                {carousel}
                            </Carousel>
                        </Col>
                    </Row>
                </Row>
            </div>
        </div>
    );
}

export default Categories;