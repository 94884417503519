import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import logoTerraViva from './../images/logoTerraViva.png';
import Environment from './../views/Environment';
import './components.css';

function NavbarUser(props) {
    const [session, setSession] = useState({});
    const [active, setActive] = useState(props.active);
    const [form, setForm] = useState({});

    useEffect(() => {
        async function isLoggedIn() {
            const response = await fetch(`${Environment()}/login/isLoggedIn`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            response.json()
            .then((data) => {
                setForm(data);
            })
            .then(() => {
                const activeLink = document.getElementById(props.active)
                activeLink.style.color = 'green';
                activeLink.style.fontWeight = 'bold';
            })
            .catch(err => {
                console.log(err);
            }); 
        }

        isLoggedIn();

      }, [props]);

    useEffect(() => {
        async function isLoggedIn() {
            const response = await fetch(`${Environment()}/login/isLoggedIn`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            response.json()
            .then((data) => {
                setSession(data);
            })
            .then(() => {
                const activeLink = document.getElementById(props.active)
                activeLink.style.color = 'green';
                activeLink.style.fontWeight = 'bold';
            })
            .catch(err => {
                console.log(err);
            }); 
        }

        isLoggedIn();

      }, [props]);

    const handleSelect = (eventKey) => setActive(eventKey);

    return (
            <Navbar className='background-nav'>
                <Container fluid className='containerNav'>
                    <Navbar.Brand className='ps-2' href="/inicio"><Image  width="150" src={logoTerraViva}/></Navbar.Brand>
                    <Nav
                        style={{ maxHeight: '100px' }}
                        activeKey={active}
                    >   
                        <Nav.Item className='flex-column text-center'>
                            <Nav.Link id="/carrito" size={'large'} className='flex-column d-lg-none linkHome' href="/carrito">
                                <ion-icon className='Icon' slot="start" name="cart"/><br />
                                <small>Carrito</small>
                            </Nav.Link>
                        </Nav.Item>
                        {form.isLoggedIn && form.role !== 'Cliente' && form.role !== 'Punto de venta' &&
                            <Nav.Item>
                                <Nav.Link className="flex-column text-center d-lg-none linkHome" id="/administracion" href="/administracion">
                                    <ion-icon className='Icon' slot="start" name="construct"/><br />
                                    <small>Admin</small>
                                </Nav.Link>
                            </Nav.Item>
                        }
                    </Nav>
                    <Nav
                        style={{ maxHeight: '200px' }}
                        activeKey={active}
                        onSelect={handleSelect}
                        className="d-none d-lg-block my-2 my-lg-0"
                    >
                        <Form className='d-flex ms-4'>

                            <Nav.Link id="/inicio" className='linkHome' href="/inicio">
                                    <ion-icon className='Icon' slot="start" name="home"/> Inicio
                            </Nav.Link>
                            <Nav.Link id="/productos" eventKey="/productos" className='linkHome' href="/productos">
                                    <ion-icon className='Icon' slot="start" name="storefront-outline"/>  Tienda
                            </Nav.Link>

                            <Nav.Link id="/AboutUs" className='linkHome' href="/AboutUs">
                                    <ion-icon className='Icon' slot="start" name="people"/>  Nosotros
                            </Nav.Link>

                            <Nav.Link id="/carrito" className='linkHome' href="/carrito">
                                    <ion-icon className='Icon' slot="start" name="cart"/>   Carrito
                            </Nav.Link>

                            {!session.isLoggedIn && 
                                <Nav.Link id="/login"  className= 'linkHome' href="/login">
                                        <ion-icon className='Icon' slot="start" name="person-circle"/>  Iniciar sesión
                                </Nav.Link>
                            }

                            {session.isLoggedIn &&
                                <Nav.Link id="/misPedidos" className='linkHome' href="/misPedidos">
                                        <ion-icon className='Icon' slot="start" name="bag-handle"/>  Mis pedidos
                                </Nav.Link>
                            }

                            {session.isLoggedIn &&
                                <Nav.Link id="/micuenta" className='linkHome' href="/micuenta">
                                        <ion-icon className='Icon' slot="start" name="person-circle"/>  Mi cuenta
                                </Nav.Link>
                            }

                            {session.isLoggedIn && session.role !== 'Cliente' && session.role !== 'Punto de venta' &&
                                <Nav.Link id="/administracion" className='linkHome' href="/administracion">
                                    <ion-icon className='Icon' slot="start" name="construct"/>
                                </Nav.Link>
                            }
                        </Form>
                    
                    </Nav>
                    <Nav
                        className="d-none d-lg-block"
                    >
                        <Nav.Link className='linkHome' href="https://wa.me/+524421111114"><ion-icon name="logo-whatsapp"/></Nav.Link>
                    </Nav>                       
                </Container>
            </Navbar>
    );
}

export default NavbarUser;