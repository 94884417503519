import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import CardProduct from './cardProduct';
import CardProductSmall from '../components/cardProductSmall';
import Environment from './../views/Environment';


function PopularProducts(){
    const [products, setProducts] = useState([]);
    const [UMs, setUMs] = useState([]);

    const arrayToObject = (array, keyField) =>
      array.reduce((obj, item) => {
          obj[item[keyField]] = item;
          return obj;
      }, {});

    useEffect(() => {
      fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .then(data => data.json())
      .then((data) => {
          setUMs(arrayToObject(data, 'idUnit'));
      })
      .catch(err => {
          console.error(err);
      });
  }, [])

    useEffect(() => {
        fetch(`${Environment()}/products/getPopularProducts`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
          setProducts(data);
        })
        .catch(err => {
            console.error(err);
        });
      }, [])

    const cards = products.slice(0, 3).map((product) =>
        <Col className='d-none d-lg-block' key={product.idProduct}>
          <CardProduct
            id={product.idProduct}
            imgExtension={product.imgExtension}
            category={product.categoryName}
            name={product.name}
            price={product.price}
            singular={product.singularName}
            plural={product.pluralName}
            discount={product.discount}
            isFloat={product.isFloat}
            isSeason={true}
            isInStock={true}
            availability={product.quantity}
            hiddeBuyButton="true"
            finalUnit={UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].singularName : ''}
          />
        </Col>
    );

    const cardsSmall = products.slice(0, 2).map((product) =>
      <Col className='d-block d-lg-none' key={product.idProduct}>
          <CardProductSmall
              id={product.idProduct}
              imgExtension={product.imgExtension}
              category={product.categoryName}
              name={product.name}
              price={product.price}
              singular={product.singularName}
              plural={product.pluralName}
              discount={product.discount}
              isFloat={product.isFloat}
              shortDescription={product.shortDescription}
              longDescription={product.longDescription}
              isSeason={true}
              isInStock={true}
              hiddeBuyButton="true"
              finalUnit={UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].singularName : ''}
          />
      </Col>                      
  );

    return(
        <Row>
            <Row>
                {cards}
            </Row>
            <Row>
                {cardsSmall}
            </Row>
        </Row>
    );
}

export default PopularProducts;