import './components.css';
import Modal from 'react-bootstrap/Modal';

function ModalSeason(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          ¿Por qué nuestro producto está fuera de temporada?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <small className='justify'>
          En nuestra búsqueda por proporcionar alimentos de alta calidad y respetuosos con el medio ambiente, 
          adoptamos prácticas sostenibles que reflejan nuestro compromiso con la salud del planeta y de las personas. 
          Nuestros productos agroecológicos se cultivan utilizando métodos que respetan los ciclos naturales, 
          promueven la biodiversidad y evitan el uso de productos químicos sintéticos. <b>Reconocemos la estacionalidad de
          los cultivos y valoramos la diversidad de nuestra tierra, lo que puede influir en la disponibilidad de nuestros productos
          a lo largo del año.</b> A través de procesos de cultivo cuidadosamente seleccionados, priorizamos la salud del suelo y la calidad
          nutricional de nuestros alimentos. Únete a nosotros en este viaje hacia una alimentación consciente y una relación armoniosa con la naturaleza.
        </small>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalSeason;