import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import AdminBar from "../components/AdminBar";
import SaleModal from '../components/saleModal';
import DisplayOrders from "../components/displayOrders";
import DisplayOrdersBySupplier from '../components/displayOrdersBySupplier';

function Orders(){
    const options = {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: "America/Mexico_City"};
    let currentDate = new Date();
    currentDate = currentDate.toLocaleString("sv-SE", options)
    let tempDate = new Date();
    tempDate.setDate(tempDate.getDate()+6);
    let defaultDate = tempDate.toLocaleString("sv-SE", options);
    
    const [date, SetDate] = useState(currentDate);
    const [datePlusSeven, setdatePlusSeven] = useState(defaultDate);
    const [modalShowSale, setModalShowSale] = useState(false);
    
    return(
        <Container className='Layout'>
            <SaleModal
            show={modalShowSale}
            onHide={() => setModalShowSale(false)}
            />
            <Row>
                <AdminBar active='/pedidos'></AdminBar>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <h3 className='mb-3'><b>Historial de <span className='green'>pedidos</span></b></h3>
                </Col>
                <Col className='text-end'>
                    <Button variant='success' onClick={() => setModalShowSale(true)}>
                        Registrar venta
                    </Button>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <Form.Group>
                        <Form.Label>Esperados del día</Form.Label>
                        <Form.Control type="date" defaultValue={date} onChange={(e) => {SetDate(e.currentTarget.value)}}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Al día</Form.Label>
                        <Form.Control type="date" defaultValue={datePlusSeven} onChange={(e) => {setdatePlusSeven(e.currentTarget.value)}}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Tabs
                defaultActiveKey="user"
                id="uncontrolled-tab-example"
                className="mb-3 myClass"
                >
                    <Tab className='green' eventKey="user" title="Por cliente">
                        <DisplayOrders date={date} datePlusSeven={datePlusSeven}></DisplayOrders>
                    </Tab>
                    <Tab className='green' eventKey="supplier" title="Por proveedor">
                        <DisplayOrdersBySupplier date={date} datePlusSeven={datePlusSeven}></DisplayOrdersBySupplier>
                    </Tab>
                </Tabs>
            </Row>
        </Container>
    );
}
export default Orders;