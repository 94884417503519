import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import Environment from '../views/Environment';
import './components.css';

function SaleModal(props) {
  const [soldProducts, setSoldProducts] = useState({});
  const [productsSearch, setProductsSearch] = useState([]);
  const [products, setProducts] = useState([]);
  const [salePoints, setSalePoints] = useState([]);
  const [salePoint, setSalePoint] = useState('');
  const [error, setError] = useState('');
  const [soldInventory, setSoldInventory] = useState([]);
  const [users, setUsers] = useState('');
  const [user, setUser] = useState('');
  const [UMs, setUMs] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [total, setTotal] = useState(0);
  
  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
      }, {});  

  useEffect(() => {
      setTotal(Object.keys(soldProducts).reduce((partialSum, product) => partialSum + (soldProducts[product].price*soldProducts[product].finalQuantity), 0));
  }, [soldProducts])

  useEffect(() => {
      fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .then(data => data.json())
      .then((data) => {
          setUMs(arrayToObject(data, 'idUnit'));
      })
      .catch(err => {
          console.error(err);
      });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/supplierOrder/getSoldInventory`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      const temp = {};

      data.forEach(obj => {
        const id = obj.idProduct;
        temp[id] = obj;
      });

      setSoldInventory(temp);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/products/getVisibleProducts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
        setProducts(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/login/getUsers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setUsers(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/login/getSellPoints`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setSalePoints(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  function filterProducts(e){
    e.preventDefault();
    const product = e.currentTarget.value;
    if(!product){
        setProductsSearch('');
    }
    else{
        setProductsSearch(products?.filter((row) => row?.name?.match(new RegExp(product, "i"))));
    }
  }

  function updateForm(value) {
    return setSoldProducts((prev) => ({ ...prev, ...value }));
  }

  function addProduct(e){
    e.preventDefault();
    const idProduct = e.currentTarget.id;
    const element = document.getElementById('quantity' + idProduct);
    let quantitySold = parseFloat(element.value);

    if(soldProducts[idProduct]){
      quantitySold += soldProducts[idProduct].quantity;
    }
    
    
    fetch(`${Environment()}/products/getProductById/${idProduct}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      updateForm({[idProduct]:{...data, quantity:quantitySold, finalQuantity:0}})
    })
    .catch(err => {
        console.error(err);
        setError('Ocurrió un error. Inténtalo   más tarde.')
    });

    element.value = '';
  }

  function deleteProduct(e){ 
    const idProduct = e.currentTarget.id;
    const copy = {...soldProducts}
    delete copy[idProduct];
    setSoldProducts(copy);
  }

  
  const allProducts = Array.from((productsSearch.length < 1) ? products : productsSearch).map((product, idx) => (
    <Form onSubmit={(e) => {addProduct(e)}} id={product.idProduct} key={product.idProduct} className="mb-3">
        <InputGroup>
            <InputGroup.Text>{product.name}</InputGroup.Text>
            <InputGroup.Text>${product.cost}</InputGroup.Text>
            <Form.Control 
            required
            type='number'
            disabled={salePoint === 'Entrega a domicilio' ? ((Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) > 0 ? false : true) : (!product.sellPointInventory || product.sellPointInventory <= 0)}
            step={product.isFloat? '0.1' : '1'}
            min={product.isFloat? '0.1' : '1'}
            max={salePoint === 'Entrega a domicilio' ? ((Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) ? (Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) : 0) : (product.sellPointInventory === null ? 0 : product.sellPointInventory)}
            xs={2}
            id={'quantity' + product.idProduct}
            />
            <InputGroup.Text>{product.pluralName}</InputGroup.Text>
            <Button disabled={ salePoint === 'Entrega a domicilio' ? ((Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) > 0 ? false : true) : (!product.sellPointInventory || product.sellPointInventory <= 0)} type='submit' id={product.idProduct} className='PrimaryBtn'><ion-icon name="add-outline"></ion-icon> Añadir</Button>
        </InputGroup>
    </Form>
  ));
  
  const productRows = Object.keys(soldProducts).map((product, idx) => (
    <tr key={product}>
        <td>{idx + 1}. {soldProducts[product].name}</td>
        <td>{[product].isFloat? (soldProducts[product].quantity) : soldProducts[product].quantity} {soldProducts[product].pluralName}</td>
        <td>
                  <InputGroup>
                      <Form.Control
                      type='number'
                      required
                      min={'0'}
                      value={soldProducts[product].finalQuantity}
                      onChange={(e) => {updateForm({[product]:{...soldProducts[product], 'finalQuantity':e.currentTarget.value}})}}
                      onFocus={(e) => {e.target.select()}}
                      maxLength={22}
                      />
                      <InputGroup.Text className={soldProducts[product].finalQuantity > 0 ? 'PrimaryBtn' : 'Pending'}>
                          {UMs[soldProducts[product].idFinalUnit] ? UMs[soldProducts[product].idFinalUnit].singularName : ''}
                      </InputGroup.Text>
                  </InputGroup>

        </td>
        <td>${soldProducts[product].price}/{UMs[soldProducts[product].idFinalUnit] ? UMs[soldProducts[product].idFinalUnit].singularName : ''}</td>
        <td>${soldProducts[product].price * soldProducts[product].finalQuantity}</td>
        <td className='text-center'><Button onClick={(e) => {deleteProduct(e)}} id={product} variant="outline-danger"><ion-icon name="trash-outline"></ion-icon></Button></td>
    </tr>
  ));
  
  const selectSalePoints = Array.from(salePoints).map((point, idx) => (
    <option key={idx} value={point.email}>{point.lastName}</option>
  ));

  const selectUsers = Array.from(users).filter(user => user.roleID === 7).map((usr, idx) => (
    <option key={idx} value={usr.email}>{usr.firstName + ' ' + usr.lastName}</option>
  ));
  
  
  function confirmSale(e){
    e.preventDefault();
    if(Object.keys(soldProducts).length > 0){
      fetch(`${Environment()}/orders/postSale`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idUser: salePoint === 'Entrega a domicilio' ? user : salePoint,
          products: soldProducts,
          date: salePoint === 'Entrega a domicilio' ? deliveryDate : new Date(),
          amount: total.toFixed(2),
          form: 1,
          isSent: true,
          isReceived: salePoint === 'Entrega a domicilio' ? false : true,
          isPayed: salePoint === 'Entrega a domicilio' ? false : true,
          isOutForDelivery : salePoint === 'Entrega a domicilio' ? false : true
        })
      })
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
          console.error(err);
          setError('Ocurrió un error. Inténtalo más tarde.')
      });
    }
    else{
      setError('Añade productos a la orden para registrar la venta.');
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Registrar venta
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Punto de venta</h5>
        <Form id='sendSale' onSubmit={(e) => {confirmSale(e)}}>
          <Form.Select required value={salePoint} onChange={(e) => {setSalePoint(e.currentTarget.value)}}>
          <option value={''}>Selecciona un punto de venta</option>
            {selectSalePoints}
          </Form.Select>
          {salePoint === 'Entrega a domicilio' && <Form.Select hidden={salePoint !== 'Entrega a domicilio'} required value={user} className='mt-3' onChange={(e) => {setUser(e.currentTarget.value)}}>
            <option value={''}>Selecciona un cliente</option>
            {selectUsers}
          </Form.Select>}
          {salePoint === 'Entrega a domicilio' && <h5 className='mt-4' hidden={salePoint !== 'Entrega a domicilio'}>Fecha de entrega</h5>}
          {salePoint === 'Entrega a domicilio' &&
          <Form.Control
            required 
            hidden={salePoint !== 'Entrega a domicilio'}
            value={deliveryDate}
            type='date'
            onChange={e => {setDeliveryDate(e.currentTarget.value)}}
          />}
          <Table responsive className='mt-5 table'>
              <thead>
                  <tr>
                      <th style={{width: "16.66%"}}>Nombre</th>
                      <th style={{width: "16.66%"}}>Cantidad</th>
                      <th style={{width: "22%"}}>Cantidad empacada</th>
                      <th style={{width: "16.66%"}}>Precio unitario</th>
                      <th style={{width: "16.66%"}}>Precio total</th>
                      <th style={{width: "10%"}} className='text-center'>Eliminar</th>
                  </tr>
              </thead>
              <tbody>
                  {productRows}
                  <tr>
                      <td colSpan={6} className='text-end'>Total: <b>${total.toFixed(2)}</b></td>
                  </tr>
              </tbody>
          </Table>
        </Form>
        <Accordion defaultActiveKey={'0'} className='mt-4'>
          <Accordion.Item eventKey="0">
              <Accordion.Header>
                  <b><ion-icon name="add"/><ion-icon name="nutrition"/> Añadir productos a la orden</b>
              </Accordion.Header>
              <Accordion.Body>
                  <Row className='mt-2'>
                      <Col>
                          <Form.Control
                          onChange={(e) => {filterProducts(e)}}
                          className='mb-3'
                          placeholder="Buscar productos"
                          />
                          <Container className='productScroll'>
                          {allProducts}
                          </Container>
                      </Col>
                  </Row>
              </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Alert variant='danger' hidden={error? false : true}>{error}</Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onHide}>Cancelar</Button>
        <Button variant='success' type='submit' form='sendSale'>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SaleModal;