import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
/* eslint-disable-next-line */
import { CategoryScale } from "chart.js";
/* eslint-disable-next-line */
import { Chart as ChartJS } from "chart.js/auto";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Environment from '../views/Environment';
import { getMonthByNumber } from '../utils/dates';
import './components.css';

function SalesByProduct() {

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({idProduct:'', UM:''});
  const [productsSearch, setProductsSearch] = useState('');
  const [isUM, setIsUM] = useState(true);

  const [orderYears, setOrderYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');

  const [productSales, setProductSales] = useState('');
  const [productQuantity, setProductQuantity] = useState('');
  const [label, setLabel] = useState('');


  useEffect(() => {
    if(product.idProduct && selectedYear){
      fetch(`${Environment()}/orders/getSoldProducts/${product.idProduct}/${selectedYear}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        setProductSales(Array.from(data).map((month) => month.amount));
        setProductQuantity(Array.from(data).map((month) => month.quantity));
        setLabel(Array.from(data).map((month) => getMonthByNumber(month.month)));
      })
      .catch(err => {
          console.error(err);
      });
    }
  }, [product, selectedYear])

  useEffect(() => {
    fetch(`${Environment()}/orders/getOrderYears`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setOrderYears(data);
      if(data.length > 0){
        setSelectedYear(data[0].year);
      }
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/categories/getCategories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
        setCategories(data);
        if(data.length){
          setCategory(data[0].name);
        }
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/products/getProducts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setProducts(data);
      if(data.length){
        setProduct({idProduct:data[0].idProduct, UM:data[0].pluralName}); 
      }
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    setCategory(category);
    if(!category){
        setProductsSearch('');
    }
    else{
        const filteredProducts = products?.filter((row) => row?.idCategory.toString()?.match(new RegExp(category, "i")));
        setProductsSearch(filteredProducts);
        if(filteredProducts.length > 0){
          setProduct({idProduct:filteredProducts[0].idProduct, UM:filteredProducts[0].pluralName});
        }
    }
  }, [category, products]);

  const labels = label;
  const data = {
    labels: labels,
    datasets: [{
      label: 'Ventas por producto',
      data: isUM? productQuantity : productSales,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)',
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
      ],
      borderWidth: 1
    }]
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value, index, ticks) {
              return isUM === true ? value + ' ' + product.UM : '$' + value;
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  const categorySelect = Array.from(categories).map((category, idx) => (
    <option key={category.idCategory} value={category.idCategory}>{category.name}</option>                    
  ));

  const productSelect = Array.from((productsSearch.length < 1) ? products : productsSearch).map((product, idx) => (
    <option key={product.idProduct} value={product.idProduct}>{product.name}</option>                    
  ));

  const selectYears = Array.from(orderYears).map((year, idx) => (
    <option key={year.year} value={year.year}>{year.year}</option>
  ));

  return (
    <div>
        <h5 className='mb-3'><b>Ventas por producto </b></h5>
        <Row className='mb-3'>
          <Col>
            <Form.Select
            size='sm'
            onChange={(e) => {setCategory(e.currentTarget.value)}}
            value={category}>
              <option key={-1} value={'All'}>Todas las categorías</option>   
              {categorySelect}
            </Form.Select>
          </Col>
          <Col>
            <Form.Select
              size='sm'
              onChange={(e) => {setProduct({idProduct:e.currentTarget.value, UM:e.currentTarget.name})}}
              value={product.idProduct}
              name={product.UM}>
                {productSelect}
              </Form.Select>
          </Col>
          <Col>
            <Form.Select size='sm' value={selectedYear} onChange={(e) => {setSelectedYear(e.currentTarget.value)}}>
              {selectYears}
            </Form.Select>
          </Col>
          <Col xs={1} className='text-end'>
            $
          </Col>
          <Col xs={2}>
            <Form.Switch
              label={product.UM}
              defaultChecked={true}
              onChange={() => {setIsUM(!isUM)}}
            />
          </Col>
        </Row>
        <Row className='d-flex justify-content-center'>
          <Col lg={10}>
            <Bar
              data={data}
              options={options}
            />
          </Col>
        </Row>
    </div>
  );
}

export default SalesByProduct;