import './components.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Environment from '../views/Environment';

function CancelOrderModal(props) {

  function CancelOrder(e){
    const idOrder = props.id;
    
    fetch(`${Environment()}/supplierOrder/cancelOrder`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({idOrder:idOrder})
    })
    .then(() => {
        window.location.href = '/pedidosproveedores';
    })
    .catch(err => {
        console.error(err);
    });
}

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cancelar pedido
        </Modal.Title>

      </Modal.Header>
      <Modal.Body>
        <p hidden={props.isDeleted}>
          ¿Estás seguro de que quieres cancelar este pedido?
        </p>
        <p hidden={!props.isDeleted}>
          ¿Estás seguro de que quieres habilitar este pedido?
        </p>
        <p className='text-danger'>
          {props.name}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
        <Button className='btn-danger' onClick={(e) => CancelOrder(e)}>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CancelOrderModal;