import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/esm/Container';
import AdminBar from '../components/AdminBar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Environment from './Environment';
import './views.css';

function StatementOfIncome() {
  const [orderYears, setOrderYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [totalSales, setTotalSales] = useState(0);
  const [waste, setWaste] = useState(0);
  const [discounts, setDiscounts] = useState(0);
  const [netSales, setNetSales] = useState(0);
  const [salesCost, setSalesCost] = useState(0);
  const [grossProfit, setGrossProfit] = useState(0);
  const [operationalExpenses, setOperationalExpenses] = useState(0);
  const [operationalProfit, setOperationalProfit] = useState(0);
  const [finantialExpenses, setFinantialExpenses] = useState(0);
  const [profitBeforeTax, setProfitBeforeTax] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [PTU, setPTU] = useState(0);
  const [netProfit, setNetProfit] = useState(0);


  useEffect(() => {
    fetch(`${Environment()}/orders/getOrderYears`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setOrderYears(data);
      if(data.length > 0){
        setSelectedYear(data[0].year);
      }
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    if(selectedYear !== ''){
      fetch(`${Environment()}/orders/totalSales/${selectedYear}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        setTotalSales(data.amount);
      })
      .catch(err => {
          console.error(err);
      });
    }
  }, [selectedYear])

  useEffect(() => {
    if(selectedYear !== ''){
      fetch(`${Environment()}/orders/totalWaste/${selectedYear}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        setWaste(data.waste);
      })
      .catch(err => {
          console.error(err);
      });
    }
  }, [selectedYear])

  useEffect(() => {
    if(selectedYear !== ''){
      fetch(`${Environment()}/orders/discounts/${selectedYear}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        setDiscounts(data.discount);
      })
      .catch(err => {
          console.error(err);
      });
    }
  }, [selectedYear])

  useEffect(() => {
    setNetSales(totalSales - waste - discounts);
    setGrossProfit(netSales - salesCost);
    setOperationalProfit(grossProfit - operationalExpenses);
    setProfitBeforeTax(operationalProfit - finantialExpenses);
    setNetProfit(profitBeforeTax - taxes - PTU)

  }, [totalSales, waste, discounts, netSales, salesCost,
     grossProfit, operationalExpenses, operationalProfit,
     finantialExpenses, profitBeforeTax, taxes, PTU])

  const selectYears = Array.from(orderYears).map((year, idx) => (
    <option key={year.year} value={year.year}>{year.year}</option>
  ));

  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout mb-5'>
        <div className='text-center'>
          <h5 className='mb-2'><b>Terra Viva</b></h5>
          <h5 className='mb-2'>Estado de resultados</h5>
          <h5 className='mb-3'>{selectedYear}</h5>
          <Row>
            <Col xs={2}>
              <Form.Select value={selectedYear} onChange={(e) => {setSelectedYear(e.currentTarget.value)}}>
                {selectYears}
              </Form.Select>
            </Col>
          </Row>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th className={"w-50"}></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ventas totales</td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={totalSales}
                onChange={(e) => {setTotalSales(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td>Merma</td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={waste}
                onChange={(e) => {setWaste(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td>Descuentos sobre ventas</td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={discounts}
                onChange={(e) => {setDiscounts(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td><b>Ventas netas</b></td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={netSales}
                onChange={(e) => {setNetSales(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td>Costo de ventas</td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={salesCost}
                onChange={(e) => {setSalesCost(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td><b>Utilidad bruta</b></td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={grossProfit}
                onChange={(e) => {setGrossProfit(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td>Gastos de operación</td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={operationalExpenses}
                onChange={(e) => {setOperationalExpenses(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td><b>Utilidad de operación</b></td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={operationalProfit}
                onChange={(e) => {setOperationalProfit(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td>Gastos financieros</td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={finantialExpenses}
                onChange={(e) => {setFinantialExpenses(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td><b>Utilidad antes de impuestos</b></td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={profitBeforeTax}
                onChange={(e) => {setProfitBeforeTax(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td>Impuestos</td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={taxes}
                onChange={(e) => {setTaxes(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td>PTU</td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={PTU}
                onChange={(e) => {setPTU(e.currentTarget.value)}}
                />
              </td>
            </tr>
            <tr>
              <td><b>Utilidad neta</b></td>
              <td>$</td>
              <td>
                <Form.Control
                type='number'
                value={netProfit}
                onChange={(e) => {setNetProfit(e.currentTarget.value)}}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </Container>
  );
}

export default StatementOfIncome;