import './components.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

function CardAdminHome(props) {
  function redirect(link){
    window.location.href = link;
  }
  return (
    <Container onClick={() => redirect(props.link)}>    
      <Card text={'success'} className='text-center adminDashboard'>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>
            <ion-icon size='large' slot="start" name={props.icon}></ion-icon>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>

  );
}

export default CardAdminHome;