import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Environment from '../views/Environment';
import './components.css';

function ModifyUserRole(props) {

  const [formError, setFormError] = useState('');
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');

  useEffect(() => {
     
    fetch(`${Environment()}/role/getRoles`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      if(data.length){
        setRoles(data);
      }
      if(props.role){
        setRole(props.role);
      }
    })
    .catch(err => {
        console.error(err);
    });
    
  }, [props.role])

  /**
 * onSubmit
 * @description Posts package product through a fetch to the server
 * @param e: Context
 */
  async function onSubmit(e) {
    e.preventDefault();
    fetch(`${Environment()}/login/modifyRole`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({role:role, email:props.email})
    })
    .then(() => (window.location.reload()))
    .catch(err => {
      console.error(err);
      setFormError('En este momento no se puede modificar la información. Inténtalo más tarde.');
    });  
  }

  function cancelBtn(){
    props.onHide();
    setRole(props.role);
  }
    
  const rolesList = Array.from(roles).map((role, idx) => (
    <option key={role.id} value={role.id}>{role.role}</option>                    
  ));

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Asignar un nuevo rol
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            Nuevo rol para {props.email}
          </p>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputRole">Selecciona un rol</Form.Label>
              <Form.Select value={role} onChange={(e) => setRole(e.target.value)}>
                {rolesList}
              </Form.Select>
            </Form.Group>
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={() => cancelBtn()}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ModifyUserRole;