import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/esm/Accordion';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import Table from 'react-bootstrap/esm/Table';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Form from 'react-bootstrap/esm/Form';
import Alert from 'react-bootstrap/esm/Alert';
import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import Environment from '../views/Environment';
import Spinner from 'react-bootstrap/esm/Spinner';

const ModifyClientOrder = React.forwardRef((props, ref) => {
    const [products, setProducts] = useState([]);
    const [productsSearch, setProductsSearch] = useState([]);
    const [orderProducts, setOrderProducts] = useState({});
    const [soldInventory, setSoldInventory] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState('');
    const [UMs, setUMs] = useState([]);
    
    function HandlePack(e, mode){
        e.preventDefault();
        setEditMode(mode);
        
        if(!mode){
            fetch(`${Environment()}/orders/postPackedProducts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        idOrder:props.idOrder,
                        orderProducts: orderProducts.map(product => ({
                            idProduct: product.idProduct,
                            finalQuantity: product.finalQuantity,
                            idUnit: product.idUnit,
                            idFinalUnit: product.idFinalUnit
                        }))
                    }
                )
            })
            .then(() => {
                window.location.reload();
            })
            .catch(err => {
                console.error(err);
            });
        }

    }
    
    const arrayToObject = (array, keyField) =>
        array.reduce((obj, item) => {
            obj[item[keyField]] = item;
            return obj;
        }, {});
        
    useEffect(() => {
        fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(data => data.json())
        .then((data) => {
            setUMs(arrayToObject(data, 'idUnit'));
        })
        .catch(err => {
            console.error(err);
        });
    }, [])

    useEffect(() => {
        fetch(`${Environment()}/supplierOrder/getSoldInventory`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
          const temp = {};

          data.forEach(obj => {
            const id = obj.idProduct;
            temp[id] = obj;
          });
  
          setSoldInventory(temp);
        })
        .catch(err => {
            console.error(err);
        });
      }, [props.updatedProduct])

    useEffect(() => {
        fetch(`${Environment()}/products/getVisibleProducts`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setProducts(data);
        })
        .catch(err => {
            console.error(err);
        });
      }, [props.setUpdatedProduct])

    function filterProducts(e){
        e.preventDefault();
        const product = e.currentTarget.value;
        if(!product){
            setProductsSearch('');
        }
        else{
            setProductsSearch(products?.filter((row) => row?.name?.match(new RegExp(product, "i"))));
        }
    }

    useEffect(() => {
        setSpinner(true);
        fetch(`${Environment()}/orders/getCartProducts/${props.idOrder}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(productsData => productsData.json())
        .then((data) => {
            setOrderProducts(data);
            setSpinner(false);
        })
        .catch(err => {
            console.error(err);
        });
    }, [props.idOrder, props.updatedProduct])

    function addProduct(e){
        e.preventDefault();

        const idProduct = e.currentTarget.id;
        const quantity = document.getElementById('quantity'+ idProduct).value;
        //Crea un arreglo de objetos para poder acceder a los atruibutos de un producto con el idProduct
        const orderProductsSorted = orderProducts.reduce((acc, item) => {
            acc[item.idProduct] = item;
            return acc;
        }, {});
        const availableProducts = products.reduce((acc, item) => {
            acc[item.idProduct] = item;
            return acc;
        }, {});
        if(Number(availableProducts[idProduct].ecommerceInventory) < Number(quantity) + (orderProductsSorted[idProduct] ? Number(orderProductsSorted[idProduct].quantity) : 0)){
            setError('Solamente tenemos ' + Number(availableProducts[idProduct].ecommerceInventory) + ' ' + (Number(availableProducts[idProduct].ecommerceInventory) > 1 ? availableProducts[idProduct].pluralName : availableProducts[idProduct].singularName) + ' de ' + availableProducts[idProduct].name + '. Escribe una cantidad más pequeña.');
            return;
        }

        fetch(`${Environment()}/orders/postCartProduct`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    idOrder:props.idOrder,
                    idProduct: idProduct,
                    quantity: quantity,
                    soldPrice:availableProducts[idProduct].price,
                    soldDiscount:availableProducts[idProduct].discount
                }
            )
        })
        .then(() => {
            document.getElementById('quantity'+ idProduct).value = '';
            props.setUpdatedProduct(props.updatedProduct+1);
        })
        .catch(err => {
            console.error(err);
        });
    }

    function deleteProduct(e){
        e.preventDefault();
        const idProduct = e.currentTarget.id;
        fetch(`${Environment()}/orders/removeCartProduct`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    idOrder:props.idOrder,
                    idProduct: idProduct
                }
            )
        })
        .then(() => {
            props.setUpdatedProduct(props.updatedProduct+1);
        })
        .catch(err => {
            console.error(err);
        });
    }

    function updateForm(attribute, value, product) {
        let temp = orderProducts;
        temp[product][attribute] = value;
    
        return setOrderProducts([...temp]);
      }

    const total = Array.from(orderProducts).reduce((partialSum, product) => partialSum + ((Number(product.soldPrice))* Number(product.finalQuantity)), 0);

    const allProducts = Array.from((productsSearch.length < 1) ? products : productsSearch).map((product, idx) => (
        <Form onSubmit={addProduct} id={product.idProduct} key={product.idProduct} className="mb-3">
            <InputGroup>
                <InputGroup.Text>{product.name}</InputGroup.Text>
                <Form.Control 
                required
                type='number'
                step={product.isFloat ? '0.1' : '1'}
                min={product.isFloat ? '0.1' : '1'}
                max={(Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) ? (Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) : 0}
                xs={2}
                id={'quantity' + product.idProduct}
                disabled={props.disabled || ((Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) > 0 ? false : true)}
                />
                <InputGroup.Text>{product.pluralName}</InputGroup.Text>
                <Button disabled={props.disabled || ((Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) > 0 ? false : true)} className='PrimaryBtn' type='submit'><ion-icon name="add-outline"></ion-icon></Button>
            </InputGroup>
        </Form>
    ));

    const productRows = Array.from(orderProducts).map((product, idx) => (
        <tr key={product.idProduct}>
            <td> {idx + 1}. {product.name}</td>
            <td>{product.quantity} {product.pluralName}
                {product.isPriceRecalculated ? ' (' + product.unitRelation * product.quantity + UMs[product.idFinalUnit].singularName + ' aprox.)' : ''}
            </td>
            <td>
                <Row>
                    <Col md={6} className='text-center'>
                        <InputGroup>
                            <Form.Control
                            disabled={!editMode}
                            type='number'
                            min={'0.1'}
                            value={product.finalQuantity}
                            onChange={(e) => {updateForm('finalQuantity', e.currentTarget.value, idx)}}
                            onFocus={(e) => {e.target.select()}}
                            />
                            <InputGroup.Text className={product.finalQuantity > 0 ? 'PrimaryBtn' : 'Pending'}>
                                {UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].singularName : ''}
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
            </td>
            <td>${Number(product.soldPrice)}</td>
            <td>${(Number(product.soldPrice)*Number(product.finalQuantity)).toFixed(2)}</td>
            <td className='text-center'><Button disabled={props.disabled} onClick={(e) => {deleteProduct(e)}} id={product.idProduct} variant="outline-danger"><ion-icon name="trash-outline"></ion-icon></Button></td>
        </tr>
    ));

    const productRowsImage = Array.from(orderProducts).map((product, idx) => (
        <tr key={product.idProduct}>
            <td>{product.name}</td>
            <td>{product.finalQuantity} {UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].singularName : ''}</td>
            <td>${(Number(product.soldPrice)).toFixed(2)}</td>
            <td>${(Number(product.soldPrice) * Number(product.finalQuantity)).toFixed(2)}</td>
        </tr>
    ));

    return(
        <div>
            <Accordion hidden={props.disabled} className='mt-5'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <b><ion-icon name="add"/><ion-icon name="nutrition"/> Añadir productos al pedido</b>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Form.Control
                            onChange={(e) => {filterProducts(e)}}
                            className='mb-3'
                            placeholder="Buscar productos"
                            />
                            <div className='productScroll'>
                                {allProducts}
                            </div>
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Alert variant='danger' hidden={!error}>{error}</Alert>
            <Table responsive className='mt-5'>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Cantidad</th> 
                        <th>
                            <Row>
                                <Col>
                                    Cantidad empacada
                                </Col>
                                <Col>
                                    <Button onClick={(e) => {HandlePack(e, !editMode)}} size="sm" variant='success'>
                                        {!editMode && <small><ion-icon name="create-outline"/></small>}
                                        {editMode && <small><ion-icon name="checkmark-outline"/></small>}
                                    </Button>
                                </Col>
                            </Row>
                        </th>
                        <th>Costo unitario</th>
                        <th>Total</th>
                        <th className='text-center'>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {productRows}
                    <tr hidden={!productRows.length}>
                        <td colSpan={6} className='text-end'>Total: <b>${total.toFixed(2)}</b></td>
                    </tr>
                </tbody>
            </Table>
            <div className='mt-1 text-center'>
                <Spinner
                hidden={!spinner}
                as="span"
                className='justify-content-center'
                animation="border"
                variant='success'
                size="lg"
                role="status"
                />
            </div>
            <Container ref={ref} hidden id={'table'} className='pb-3 pt-3'>
                <h4><b>Pedido de {props.client}</b></h4>
                <div className='text'muted>
                    <p>ID: {props.idOrder}</p>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Precio unitario</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productRowsImage}
                        <tr>
                            <td colSpan={6} className='text-end'>Total: <b>${total.toFixed(2)}</b></td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </div>
    );

});

export default ModifyClientOrder;