import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'
import logoTerraViva from './../images/logoTerraViva.png'
import './components.css';

function NavbarUser() {
    
    return (
        <div className="header">
            <Container>
                <Navbar style={{display: 'flex', justifyContent: 'center'}} className='border bg-white' fixed="top">
                    <Container fluid className='containerNav'>
                    <Navbar.Brand className='ps-2' href="/inicio"><Image  width="100" src={logoTerraViva}/></Navbar.Brand>
                        <Nav
                            style={{ maxHeight: '100px' }}
                        >
                                <Nav.Link className='linkAdmin text-center' href="/inicio">
                                    <ion-icon className='Icon' slot="start"  name="home"></ion-icon><br />
                                    <small>Inicio</small>
                                </Nav.Link>
                                <Nav.Link className='linkAdmin text-center' href="/administracion">
                                    <ion-icon className='Icon' slot="start"  name="construct"></ion-icon><br />
                                    <small>Admin</small>
                                </Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
                
            </Container>
        </div>
    );
}

export default NavbarUser;