import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/esm/Alert';
import AdminBar from '../components/AdminBar';
import InputGroup from 'react-bootstrap/InputGroup';
import Environment from './Environment';
import './../components/components.css';
import './views.css';

function ProductInventory() {

  const [user, setUser] = useState({});

  const [products, setProducts] = useState([]);
  const [productsSearch, setProductsSearch] = useState([]);

  const [show, setShow] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState(0);

  const numFor = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });

  const [error, setError] = useState('');

  const [disabledInptOnline, setDisabledInptOnline] = useState([])
  const [disabledInptOnSite, setDisabledInptOnSite] = useState([])
  const [soldInventory, setSoldInventory] = useState([])


  function updateDisabledForm(index, name, value, idProduct, e) {
    e.preventDefault();
    if((!disabledInptOnline[index] && name === 'disabledInptOnline') || (!disabledInptOnSite[index] && name === 'disabledInptOnSite')){
      fetch(`${Environment()}/supplierOrder/modifyInventory`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            field: name, 
            value: value, 
            idProduct: idProduct
          }
        )
      })
      .then(() => {
        window.location.reload();
      })  
      .catch(err => {
        console.error(err);
        setError("En este momento no se pueden registrar cambios al inventario. Inténtalo más tarde.");
      });
    }
    if(name === 'disabledInptOnline'){
      let updatedItems = [...disabledInptOnline];
      updatedItems[index] = !updatedItems[index];
      setDisabledInptOnline(updatedItems);
    } else {
      let updatedItems = [...disabledInptOnSite];
      updatedItems[index] = !updatedItems[index];
      setDisabledInptOnSite(updatedItems);
    }
  }

  function updateForm(index, name, e, productArray) {
    
    if(productArray === 'products'){
      const updatedItems = [...products];
      updatedItems[index][name] = e.target.value;
      setProducts(updatedItems);

    } else {
      const updatedItems = [...productsSearch];
      updatedItems[index][name] = e.target.value;
      setProductsSearch(updatedItems);
    }

  }

  const handleClose = () => setShow(false);
    function handleShow(e){
        setShow(true);
        setSelectedPicture(e.currentTarget.id);
    }

  function filterProducts(e){
    e.preventDefault();
    const product = e.currentTarget.value;
    if(!product){
        setProductsSearch('');
    }
    else{
        setProductsSearch(products?.filter((row) => 
                            row?.name?.match(new RegExp(product, "i")) ||
                            row?.categoryName?.match(new RegExp(product, "i")) ||
                            row?.supplierName?.match(new RegExp(product, "i"))
        ));
    }
  }

  useEffect(() => {
    fetch(`${Environment()}/login/isLoggedIn`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(session => session.json())
    .then((session) => {
        setUser(session);
    })
  }, [])

  useEffect(() => {
      fetch(`${Environment()}/supplierOrder/getInventory`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        setProducts(data);
        const inventoryLen = data.length;
        setDisabledInptOnline(Array(inventoryLen).fill(true))
        setDisabledInptOnSite(Array(inventoryLen).fill(true))
      })
      .catch(err => {
          console.error(err);
      });
  }, [])

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {});

  useEffect(() => {
    fetch(`${Environment()}/supplierOrder/getSoldInventory`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      
      setSoldInventory(arrayToObject(data, 'idProduct'));
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  const productRows = Array.from((productsSearch.length < 1) ? products : productsSearch).map((product, idx) => (
        <tr key={product.idProduct}>
          <td><Image id={`${product.idProduct}${product.imgExtension}`} onClick={(e) => {handleShow(e)}} style={{ width: "calc(50px + 3vw)" }} src={`${Environment()}/product${product.idProduct}${product.imgExtension}`}></Image></td>
          <td>{product.name}</td>
          <td>{product.quantity} {product.pluralName}</td>
          <td>{numFor.format(product.cost * product.unitRelation)}/{product.singularName}</td>
          <td>{numFor.format((product.cost * product.quantity * product.unitRelation))}</td>
          <td>
            <Row>
              <Form onSubmit={(e) => updateDisabledForm(idx, 'disabledInptOnSite', product.sellPointInventory, product.idProduct, e)}>
                <InputGroup>
                  <Form.Control
                  type='number'
                  disabled={disabledInptOnSite[idx] != null ? disabledInptOnSite[idx] : false}
                  size='sm'
                  value={product.sellPointInventory === null ? '' : product.sellPointInventory}
                  onChange={(e) => updateForm(idx, 'sellPointInventory', e, (productsSearch.length < 1) ? 'products' : 'ProductsSearch')}
                  max={Number(product.quantity) - product.ecommerceInventory}
                  min={0}
                  />
                  <Button type='submit' className='PrimaryBtn'>
                    { disabledInptOnSite[idx] !== false ?
                      <ion-icon size='small' name="pencil"></ion-icon> :
                      <ion-icon size='small' name="checkmark"></ion-icon>
                    }
                  </Button>
                </InputGroup>
              </Form>
            </Row>
          </td>
          <td>
            <Row>
              <Form onSubmit={(e) => updateDisabledForm(idx, 'disabledInptOnline', product.ecommerceInventory, product.idProduct, e)}>
                <InputGroup>
                  <Form.Control
                  type='number'
                  disabled={disabledInptOnline[idx] != null ? disabledInptOnline[idx] : false}
                  size='sm'
                  value={product.ecommerceInventory === null ? '' : product.ecommerceInventory}
                  onChange={(e) => updateForm(idx, 'ecommerceInventory', e, (productsSearch.length < 1) ? 'products' : 'ProductsSearch')}
                  max={Number(product.quantity) - product.sellPointInventory}
                  min={soldInventory[product.idProduct] ? soldInventory[product.idProduct].quantity : 0}
                  />
                  <Button key={product.idProduct} className='PrimaryBtn' type='submit'>
                    { disabledInptOnline[idx] !== false ?
                      <ion-icon size='small' name="pencil"></ion-icon> :
                      <ion-icon size='small' name="checkmark"></ion-icon>
                    }
                  </Button>
                </InputGroup>
              </Form>
            </Row>
          </td>
          <td>{((Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) ? (Number(product.ecommerceInventory) - (soldInventory[product.idProduct] ? Number(soldInventory[product.idProduct].quantity) : 0)) : 0)}</td>
          <td>{soldInventory[product.idProduct] ? soldInventory[product.idProduct].quantity : 0}</td>
        </tr>
        )
  );

  const total = Array.from(products).reduce((partialSum, product) => partialSum + (product.cost*product.quantity*product.unitRelation), 0);

  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout'>
        <h3 className='mb-5'><b>Inventario de <span className='green'>productos</span></b></h3>
        <Form.Control
        placeholder="Buscar producto"
        className="mb-5"
        onChange={(e) => {filterProducts(e)}}
        /> 
        <Alert hidden={error ? false : true} variant='danger'>{error}</Alert>            
        <Table>
          <thead>
              <tr>
                  <th></th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Valor unitario</th>
                  <th>Valor total</th>
                  <th>Inventario en mercado</th>
                  <th>Inventario en línea</th>
                  <th>Inventario en línea disponible</th>
                  <th>Inventario en línea apartado</th>
              </tr>
          </thead>
          <tbody>
              {productRows}
              {user.role === 'Gerencia' &&
                <tr>
                    <td colSpan={6} className='text-end'>Valor de inventario total: <b>{numFor.format(total)}</b></td>
                </tr>
              }
          </tbody>
        </Table>
      </Container>
      <Modal className='d-flex justify-content-center' centered size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
          <Image
          fluid 
          style={{ width: "calc(50px + 40vw)" }} 
          src={`${Environment()}/product${selectedPicture}`}>
          </Image>
          </Modal.Body>
      </Modal>
    </Container>
  );
}

export default ProductInventory;