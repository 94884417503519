import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/esm/Form';
import Tabs from 'react-bootstrap/esm/Tabs';
import Tab from 'react-bootstrap/esm/Tab';
import AdminBar from '../components/AdminBar';
import GroupedOrders from '../components/groupedOrders';
import DeliveredGroupedOrders from '../components/deliveredGroupedOrders';
import { useParams } from 'react-router-dom';
import './views.css';

function DeliverOrders(props) {
  let { checked } = useParams();
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout mb-5'>
        <h3 className='mb-5'><b>Repartir <span className='green'>pedidos</span></b></h3>
        <Form.Control
        className='mb-5'
        type='date'
        value={date}
        onChange={(e) => {setDate(e.currentTarget.value)}}
        />
        <Tabs
        defaultActiveKey="products"
        id="uncontrolled-tab-example"
        className="mb-3 myClass"
        justify
        >
            <Tab className='green' eventKey="products" title="Pendientes">
              <GroupedOrders date={date} checked={checked}/>
            </Tab>
            <Tab eventKey="categories" title="Entregados">
              <DeliveredGroupedOrders date={date}/>
            </Tab>
        </Tabs>
      </Container>
    </Container>
  );
}

export default DeliverOrders;