import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Environment from '../views/Environment';
import './components.css';

function WasteModal(props) {

  const [product, setProduct] = useState({});

  useEffect(() => {

    if(props.idproduct){
      fetch(`${Environment()}/products/getProductById/${props.idproduct}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
          setProduct(data);
      })
      .catch(err => {
          console.error(err);
      });
    }

  }, [props])

  function registerWaste(e){
    e.preventDefault();
    const waste = document.getElementById('waste').value;
    const notes = document.getElementById('notes').value;

    fetch(`${Environment()}/supplierOrder/postWaste`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idOrder: props.idorder,
        idProduct: props.idproduct,
        waste: waste,
        notes: notes
      })
    })
    .then(() => {
        window.location.reload();
    })
    .catch(err => {
        console.error(err);
    });
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
    <Form onSubmit={registerWaste}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title} de {product.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='mb-3'>
            <Form.Label>Cantidad *</Form.Label>
            <Col xs={3}>
              <Form.Control
              required
              placeholder={props.quantity}
              defaultValue={props.waste}
              id='waste'
              type='number'
              step={product.isFloat ? '0.1' : '1'}
              min='0'
              max={props.quantity}
              />
            </Col>
            <Col xs={3}>
              <p>{product.pluralName} de {product.name}</p>
            </Col>
          </Row>
          <Form.Group className='mb-3'>
            <Form.Label>Justificación *</Form.Label>
            <Form.Control
            required
            placeholder='Estaban podridos'
            id='notes'
            type='text'
            as='textarea'
            rows={3}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-success' onClick={props.onHide}>Cancelar</Button>
          <Button variant='secondary' type='submit'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default WasteModal;