import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/esm/Form';
import Alert from 'react-bootstrap/esm/Alert';
import Button from 'react-bootstrap/esm/Button';
import NavbarUser from '../components/navbar';
import Environment from './Environment';
import FooterUser from '../components/footerUser';
import './views.css';

function ForgotMyPassword() {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [email, setEmail] = useState('');

  function emailSubmit(e){
    e.preventDefault();
    fetch(`${Environment()}/login/forgotMyPassword`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({email:email})
    })
    .then((response) => {
        if(response.status === 200){
          setError('');
          setSuccess('Te hemos enviado a tu correo las instrucciones para restablecer tu contraseña.')
        }
        else if(response.status === 600){
          setError('El correo ingresado no está registrado.')
        }
        else if(response.status === 500){
          setError('Lo sentimos, en este momento no podemos realizar la solicitud. Inténtalo más tarde.')
        }
    })
    .catch(err => {
        console.error(err);
        setError('Lo sentimos, en este momento no podemos realizar la solicitud. Inténtalo más tarde.')
    });
  }

  return (
    <Container>
      <NavbarUser active={'/login'}></NavbarUser>
      <Container className='Layout'>
        <h3 className='mb-2'><b>Restableciemiento de contraseña</b></h3>
        <h5 className='mb-5'>¿Has olvidado tu contraseña? Solicita restablecimiento de contraseña.</h5>
        <Form onSubmit={(e) => {emailSubmit(e)}}>
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} required className='mb-2' size='lg'/>
          <Button className='mb-5' type='submit' variant='success' size='lg'>Solicitar restablecimiento </Button>
        </Form>
        <Alert hidden={error? false:true} variant='danger'>{error}</Alert>
        <Alert hidden={success? false:true} variant='success'>{success}</Alert>
      </Container>
      <FooterUser active='/login'></FooterUser>
    </Container>
  );
}

export default ForgotMyPassword;