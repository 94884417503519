import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
/* eslint-disable-next-line */
import { CategoryScale } from "chart.js";
/* eslint-disable-next-line */
import { Chart as ChartJS } from "chart.js/auto";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Environment from '../views/Environment';
import { getMonths } from '../utils/dates';
import './components.css';

function SalesBySellPoint() {

  const [orderYears, setOrderYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState((new Date()).getMonth()+1);

  const [sales, setSales] = useState('');

  useEffect(() => {
    if(selectedMonth && selectedYear){
      fetch(`${Environment()}/orders/getSalesBySalePoint/${selectedMonth}/${selectedYear}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        setSales(data);
      })
      .catch(err => {
          console.error(err);
      });
    }
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    fetch(`${Environment()}/orders/getOrderYears`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setOrderYears(data);
      if(data.length > 0){
        setSelectedYear(data[0].year);
      }
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  const labels = Array.from(sales).map((point) => point.lastName);
  const data = {
    labels: labels,
    datasets: [{
      label: 'Ventas por producto',
      data:  Array.from(sales).map((point) => point.amount),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)',
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
      ],
      borderWidth: 1
    }]
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value, index, ticks) {
              return '$' + value;
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  const selectMonth = Array.from(getMonths()).map((month, idx) => (
    <option key={idx+1} value={idx+1}>{month}</option>
  ));

  const selectYears = Array.from(orderYears).map((year, idx) => (
    <option key={year.year} value={year.year}>{year.year}</option>
  ));

  return (
    <div className='mb-5'>
        <h5 className='mt-5 mb-3'><b>Ventas por punto de venta </b></h5>
        <Row className='mb-3'>
          <Col>
            <Form.Select
            size='sm'
            onChange={(e) => {setSelectedMonth(e.currentTarget.value)}}
            value={selectedMonth}>
              {selectMonth}
            </Form.Select>
          </Col>
          <Col>
            <Form.Select 
            size='sm' 
            onChange={(e) => {setSelectedYear(e.currentTarget.value)}}
            value={selectedYear}>
              {selectYears}
            </Form.Select>
          </Col>
        </Row>
        <Row className='d-flex justify-content-center'>
          <Col lg={10}>
            <Bar
              data={data}
              options={options}
            />
          </Col>
        </Row>
    </div>
  );
}

export default SalesBySellPoint;