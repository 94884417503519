import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Environment from '../views/Environment';
import './components.css';

function NewProductModal(props) {
  const [productId, setProductId] = useState(props.id);
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [UMs, setUMs] = useState([]);
  const [UMObject, setUMObject] = useState({});
  const [formError, setFormError] = useState('');
  const [product, setProduct] = useState(
    {
      name: '',
      shortDescription: '',
      longDescription: '',
      image: '',
      idCategory: '',
      idSupplier: '',
      UM: '',
      idFinalUnit: '',
      isPriceRecalculated: '',
      unitRelation: '',
      brutePrice: '',
      price: '',
      discount: '',
      cost: '',
      seasonStartDay: '',
      seasonStartMonth: '',
      seasonEndDay: '',
      seasonEndMonth: ''
    }
  );

  //Disable scroll on number inputs
  useEffect(() => {
    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
          document.activeElement.blur();
      }
    });
  }, [])

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {});

  useEffect(() => {
    if(props.id !== null){
      fetch(`${Environment()}/products/getProductById/${props.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
          setProduct(data);
          if(data.seasonStart && data.seasonEnd){
            const seasonStartDay = data.seasonStart.split(",")[0]
            const seasonStartMonth = data.seasonStart.split(",")[1]
            const seasonEndDay = data.seasonEnd.split(",")[0]
            const seasonEndMonth = data.seasonEnd.split(",")[1]
            
            updateForm({
              seasonStartDay:seasonStartDay,
              seasonStartMonth: seasonStartMonth,
              seasonEndDay: seasonEndDay,
              seasonEndMonth: seasonEndMonth,
            });
          }

          setProductId(props.id);
          if(data.price){
            updateForm({brutePrice: (parseFloat(data.price) + parseFloat(data.discount)).toFixed(2)})
          }
      })
      .catch(err => {
          console.error(err);
      });
    }

  }, [props.id])

  useEffect(() => {
    fetch(`${Environment()}/categories/getCategories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
        setCategories(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/suppliers/getSuppliers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setSuppliers(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setUMs(data);
      setUMObject(arrayToObject(data, 'idUnit'));
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setProduct((prev) => ({ ...prev, ...value }));
    }

   /**
   * onSubmit
   * @description Posts category through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(product.image && product.image.type !== "image/png" && product.image.type !== "image/jpg" && product.image.type !== "image/jpeg"){
        setFormError("La imagen no es png, jpg o jpeg.")
        return;
      }

      const formData = new FormData();
      formData.append('idProduct', product.idProduct);
      formData.append('image', product.image);
      formData.append('name', product.name);
      formData.append('shortDescription', product.shortDescription);
      formData.append('longDescription', product.longDescription);
      formData.append('idCategory', product.idCategory);
      formData.append('idSupplier', product.idSupplier);
      formData.append('idUnit', product.idUnit);
      formData.append('idFinalUnit', product.idFinalUnit);
      formData.append('isPriceRecalculated', product.isPriceRecalculated);
      formData.append('unitRelation', product.unitRelation);
      formData.append('price', product.price);
      formData.append('discount', product.discount);
      formData.append('cost', product.cost);
      formData.append('seasonStart', product.seasonStartDay && product.seasonStartMonth ? (product.seasonStartDay + "," + product.seasonStartMonth) : null);
      formData.append('seasonEnd', product.seasonEndDay && product.seasonEndMonth ? (product.seasonEndDay + "," + product.seasonEndMonth) : null);

      fetch(`${Environment()}/products/getProduct/${product.name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        if(data.length && parseInt(data[0].idProduct) !== parseInt(product.idProduct)){
          setFormError("El nombre del producto ya existe");
          return;
        }
        else{
          fetch(`${Environment()}/products/modifyProduct/${product.idProduct}`, {
            method: 'POST',
            body: formData
          });
          
          setProduct(
            {
              name: '',
              shortDescription: '',
              longDescription: '',
              image: '',
              idCategory: '',
              idSupplier: '',
              UM: '',
              idFinalUnit: '',
              isPriceRecalculated: '',
              unitRelation: '',
              brutePrice: '',
              price: '',
              discount: '',
              cost: '',
              seasonEndDay: '',
              seasonEndMonth: ''
            }
          );
            
          window.location.reload();
        }
      })
  }

  const months = [
    {number:1, name:'enero'}, 
    {number:2, name:'febrero'},
    {number:3, name:'marzo'},
    {number:4, name:'abril'},
    {number:5, name:'mayo'},
    {number:6, name:'junio'},
    {number:7, name:'julio'},
    {number:8, name:'agosto'},
    {number:9, name:'septiembre'},
    {number:10, name:'octubre'},
    {number:11, name:'noviembre'},
    {number:12, name:'diciembre'}
  ];

  const selectMonths = Array.from(months).map((month, idx) => (
  <option key={month.number} value={month.number}>{month.name}</option>
  ));

  const categorySelect = Array.from(categories).map((category, idx) => (
    <option key={category.idCategory} value={category.idCategory}>{category.name}</option>                    
  ));

  const supplierSelect = Array.from(suppliers).map((supplier, idx) => (
    <option key={supplier.idSupplier} value={supplier.idSupplier}>{supplier.name}</option>                    
  ));

  const UMSelect = Array.from(UMs).map((UM, idx) => (
    <option key={UM.idUnit} value={UM.idUnit}>{UM.singularName}</option>                    
  ));

  const onFileChange = (e) => {
    if(e.target.files.length === 0) {
      updateForm({ image: ''});
    }
    else{
      updateForm({ image: e.target.files[0] })
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b>{props.title}</b>
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductName"><b>Nombre *</b></Form.Label>
              <Form.Control
                required
                type="text"
                id="inputProductName"
                aria-describedby="productName"
                placeholder="Manzana roja"
                onChange={(e) => updateForm({ name: e.target.value })}
                value={product.name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductDesc"><b>Descripción corta *</b></Form.Label>
              <Form.Control
                required
                type="text"
                id="inputProductDesc"
                aria-describedby="productDescription"
                placeholder="Manzana Royal Gala"
                onChange={(e) => updateForm({ shortDescription: e.target.value })}
                value={product.shortDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductLongDesc"><b>Descripción larga</b></Form.Label>
              <Form.Control
                as="textarea" rows={3}
                id="inputProductLongDesc"
                aria-describedby="productLongDescription"
                placeholder="Manzanas jugosas que le encantarán a tu familia."
                onChange={(e) => updateForm({ longDescription: e.target.value })}
                value={product.longDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductCat"><b>Categoría *</b></Form.Label>
              <Form.Select
                required
                id="inputProductCat"
                aria-describedby="productCategory"
                onChange={(e) => updateForm({ idCategory: e.target.value })}
                value={product.idCategory}
              >
                <option value=''>Selecciona una categoría</option>  
                {categorySelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductSupp"><b>Proveedor *</b></Form.Label>
              <Form.Select
                required
                id="inputProductSuppt"
                aria-describedby="productSupplier"
                onChange={(e) => updateForm({ idSupplier: e.target.value })}
                value={product.idSupplier}
              >
                <option value=''>Selecciona un proveedor</option>  
                {supplierSelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductDesc"><b>Unidad de medida pedido *</b></Form.Label>
              <Form.Select
                required
                type="text"
                id="inputProductUM"
                aria-describedby="productUM"
                placeholder="KG"
                onChange={(e) => updateForm({ idUnit: e.target.value })}
                value={product.idUnit}
              >
                <option value=''>Selecciona una unidad de medida</option>
                {UMSelect} 
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductDesc"><b>Unidad de medida venta *</b></Form.Label>
              <Form.Select
                required
                type="text"
                id="inputProductUM"
                aria-describedby="productUM"
                placeholder="KG"
                onChange={(e) => updateForm({ idFinalUnit: e.target.value })}
                value={product.idFinalUnit}
              >
                <option value=''>Selecciona una unidad de medida</option>
                {UMSelect} 
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>¿El producto se tiene que pesar o medir antes de la entrega? *</b></Form.Label>
              <Form.Select
                required
                type="text"
                placeholder="KG"
                onChange={(e) => {updateForm({ isPriceRecalculated : e.target.value }); updateForm({ unitRelation: e.target.value === 0 ? 1 : product.unitRelation })}}
                value={product.isPriceRecalculated}
              >
                <option value=''>Selecciona una opción</option>
                <option value={1}>Si</option>
                <option value={0}>No</option>
              </Form.Select>
            </Form.Group>
            <Form.Label className='green'><b>Escribe la relación que tienen apróximadamente ambas unidades de medida *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>1 {UMObject[product.idUnit] ? UMObject[product.idUnit].singularName : '' } es igual a </InputGroup.Text>
              <Form.Control
                required
                disabled={product.isPriceRecalculated === 0}
                type="number"
                placeholder="30.99"
                step={ UMObject[product.idFinalUnit] ? UMObject[product.idFinalUnit].isFloat === 1 ? '0.001' : '1' : ''}
                min={UMObject[product.idFinalUnit] ? UMObject[product.idFinalUnit].isFloat === 1 ? '0.001' : '1' : ''}
                onChange={(e) => {updateForm({ unitRelation: e.target.value})}}
                value={product.unitRelation}
              />
              <InputGroup.Text>{UMObject[product.idFinalUnit]? UMObject[product.idFinalUnit].singularName : ''}</InputGroup.Text>
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductCost"><b>Costo (costo de producción o pago al productor) *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                id="inputProductCost"
                aria-describedby="productCost"
                placeholder="12"
                min={0}
                onChange={(e) => updateForm({ cost: e.target.value })}
                value={product.cost}
              />
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductPrice"><b>Precio (precio a los clientes de Terra Viva) *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                id="inputProductPrice"
                aria-describedby="productPrice"
                placeholder="30.99"
                min={0}
                onChange={(e) => {updateForm({ brutePrice: e.target.value}); updateForm({ price: e.target.value - product.discount});}}
                value={product.brutePrice}
              />
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductDiscount"><b>Descuento *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                id="inputProductDiscount"
                aria-describedby="productDiscount"
                placeholder="0.99"
                min={0}
                max={product.brutePrice}
                onChange={(e) => { updateForm({ discount: e.target.value }); updateForm({ price: product.brutePrice - e.target.value});}}
                value={product.discount}
              />
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductDiscount"><b>Precio con descuento</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                disabled
                type="number"
                id="inputProductPriceDiscount"
                aria-describedby="productPrice"
                placeholder="30"
                min={0}
                value={product.price}
              />
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductDiscount"><b>Inicio de temporada</b></Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                value={product.seasonStartDay}
                min={1}
                max={31}
                onChange={(e) => updateForm({ seasonStartDay: e.target.value })}
                placeholder='Escribe un día'
                >
              </Form.Control>
              <Form.Select
                type="text"
                value={product.seasonStartMonth}
                onChange={(e) => updateForm({ seasonStartMonth: e.target.value })}
                >
                <option value=''>Selecciona un mes</option>
                {selectMonths}
              </Form.Select>
            </InputGroup>
            <Form.Label className='green' htmlFor="inputProductDiscount"><b>Fin de temporada</b></Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                value={product.seasonEndDay}
                min={1}
                max={31}
                placeholder='Escribe un día'
                onChange={(e) => updateForm({ seasonEndDay: e.target.value })}
              >
              </Form.Control>
              <Form.Select
                type="text"
                value={product.seasonEndMonth}
                onChange={(e) => updateForm({ seasonEndMonth: e.target.value })}
              >
                <option value=''>Selecciona un mes</option>
                {selectMonths}
              </Form.Select>
            </InputGroup>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductImage"><b>Imagen *</b></Form.Label>
              <Form.Control 
                type='file'
                id='inputImage'
                aria-describedby="productImage"
                onChange={(e) => onFileChange(e)}
              />
              <Form.Text>Selecciona una imagen png, jpg o jpeg.</Form.Text>
            </Form.Group>
            { product.imgExtension && !product.image &&
            <Image 
              className='mb-3 mx-auto d-block'
              style={{ width: "calc(150px + 10vw)" }}
              src={`${Environment()}/product${productId}${product.imgExtension}`}>
            </Image>
            }
            { product.image &&
              <Image 
                className='mb-3 mx-auto d-block'
                style={{ width: "calc(150px + 10vw)" }}
                src={URL.createObjectURL(product.image)}
                >
              </Image>
            }
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewProductModal;