import Container from 'react-bootstrap/esm/Container';
import Tabs from 'react-bootstrap/esm/Tabs';
import Tab from 'react-bootstrap/esm/Tab';
import AdminBar from './../components/AdminBar';
import ProductTable from './../components/productTable';
import CategoryTable from './../components/categoryTable';
import './views.css';

function AdminProducts() {

  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout'>
        <h3 className='mb-5'><b>Administrar <span className='green'>productos</span></b></h3>
        <Tabs
        defaultActiveKey="products"
        id="uncontrolled-tab-example"
        className="mb-3"
        >
            <Tab className='green' eventKey="products" title="Productos">
                <ProductTable />
            </Tab>
            <Tab eventKey="categories" title="Categorías">
                <CategoryTable />
            </Tab>
        </Tabs>
      </Container>
    </Container>
  );
}

export default AdminProducts;