import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Environment from '../views/Environment';
import './components.css';

function NewSupplierOrderModal(props) {

  const [formError, setFormError] = useState('');
  const [suppliers, setSuppliers] = useState({});
  const [supplier, setSupplier] = useState('');

  useEffect(() => {
    fetch(`${Environment()}/suppliers/getSuppliers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setSuppliers(data);
    })
    .catch(err => {
        console.error(err);
        setFormError('Lo sentimos, ocurrió un error. Inténtalo más tarde.')
    });
  }, [])

  const supplierSelect = Array.from(suppliers).map((supplier, idx) => (
    <option key={supplier.idSupplier} value={supplier.idSupplier}>{supplier.name}</option>                    
  ));

  function onSubmit(){
    fetch(`${Environment()}/supplierOrder/postOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({idSupplier:supplier})
    })
    .catch(err => {
        console.error(err);
        setFormError('Lo sentimos, ocurrió un error. Inténtalo más tarde.')
    });
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
          <Form.Group className="mb-3">
            <Form.Label className='green' htmlFor="inputProductSupp"><b>Proveedor *</b></Form.Label>
            <Form.Select
              required
              id="inputProductSuppt"
              aria-describedby="productSupplier"
              onChange={(e) =>  setSupplier(e.target.value)}
              value={supplier}
            >
              <option value=''>Selecciona un proveedor</option>  
              {supplierSelect}
            </Form.Select>
          </Form.Group>
          <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewSupplierOrderModal;