import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './components.css';

function OrderButton(props) {

  const selectedStatus = props.status;

  let status = 'Sin enviar';
  let statusColor = 'text-info'

  if(props.isCanceled){
    status = 'Cancelado';
    statusColor = 'text-secondary';
  }
  else if((!props.isReceived) && props.isSent){
    status = 'Por recibir';
    statusColor = 'text-primary';
  }
  else if(props.isReceived){
    status = 'Completado';
    statusColor = 'text-success';
  }

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  function redirect(link){
    window.location.href = link;
  }

  return (
    <Container onClick={() => redirect('/pedidosproveedores/' + props.idOrder)}>
      {
        (selectedStatus === 'Todos' || status === selectedStatus) &&
        <Card className='adminDashboard'>
          <Card.Body>
            <Card.Title>
              <b>{props.supplierName}</b>
            </Card.Title>
            <Card.Text className='text-muted'>
              {new Date(props.dateTime).toLocaleDateString('es-MX', options)}
            </Card.Text>
            <Card.Text className='text-muted'>
              {new Date(props.dateTime).toLocaleTimeString('en-US')}
            </Card.Text>
            <Row>
              <Col>
                <Card.Text className='text-muted'>
                  ID: {props.idOrder}
                </Card.Text>
              </Col>
              <Col>
                <Card.Text className={statusColor}>
                  <b>Status: {status}</b>
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      }
    </Container>
  );
}

export default OrderButton;