import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import logoTerraViva from './../images/logoTerraViva.png';
import './components.css';

function FooterHome() {
    return (
            <Navbar className='home-blue-bg'>
                <Container fluid className='containerNav'> 
                    <Navbar.Brand className='ps-2' href="/inicio"><Image  width="70" src={logoTerraViva}/></Navbar.Brand>
                    <Nav
                        style={{ maxHeight: '100px' }}
                        className="mx-auto text-center"
                    >
                        <Nav.Item className='d-none d-lg-block'>
                            <Nav.Link className="antiquewhite-text">
                                    <small>Encuéntranos en:</small>
                            </Nav.Link>
                        </Nav.Item> 
                        <Nav.Item>
                            <Nav.Link className="flex-column antiquewhite-text" id="/inicio" href="/inicio">
                                    <ion-icon slot="start" name="logo-facebook"/><br />
                            </Nav.Link>
                        </Nav.Item> 

                        <Nav.Item>
                            <Nav.Link className="flex-column antiquewhite-text" id="/productos" eventKey="/productos" href="/productos">
                                    <ion-icon slot="start" name="logo-instagram"/><br />
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link className="flex-column antiquewhite-text" id="/AboutUs" href="/AboutUs">
                                    <ion-icon slot="start" name="logo-tiktok"/><br />
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link className="flex-column antiquewhite-text" id="/AboutUs" href="/AboutUs">
                                    <ion-icon slot="start" name="logo-whatsapp"/><br />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Nav
                        style={{ maxHeight: '100px' }}
                        className="mx-auto text-center"
                    >
                        <Nav.Item>
                            <Nav.Link className="antiquewhite-text" id="/inicio" href="/inicio">
                                    <small>Aviso de privacidad</small>
                            </Nav.Link>
                        </Nav.Item> 
                    </Nav>                           
                </Container>
            </Navbar>
    );
}

export default FooterHome;