import React, { useEffect, useState } from 'react';
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Environment from '../views/Environment';

function DisplayOrdersBySupplier(props){

    const [productOrders, setProductOrders] = useState([]);

    const [user, setUser] = useState({});


    useEffect(() => {
        fetch(`${Environment()}/login/isLoggedIn`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(session => session.json())
        .then((session) => {
            setUser(session);
        })
    }, [])

    function modifyProductsAvailability(e){
        const idProduct = e.currentTarget.id;

        fetch(`${Environment()}/orders/modifyProductsAvailability`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                idProduct: idProduct,
                date: props.date,
                datePlusSeven: props.datePlusSeven
            })
        })
        .then(() => {
            window.location.reload()
        })
        .catch(err => {
            console.error(err);
        });
    }
    
    useEffect(() => {
        setProductOrders([]);
        if (props.date){
            fetch(`${Environment()}/orders/getSuppliersWithOrdersByWeek/${props.date}/${props.datePlusSeven}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(data => data.json())
            .then((data) => {
                setProductOrders(data);
            })
            .catch(err => {
                console.error(err);
            });
        }
    }, [props.date, props.datePlusSeven])

    return(
        <Container className='mb-5 mt-2'>

        {Object.keys(productOrders).reverse().map((order, index) => {
            return(
                <div className='mt-2 mb-5' key={productOrders[order][0].idSupplier}>
                    <h5>Recomendación de pedido para {productOrders[order][0].supplier}</h5>
                    <Table>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Cantidad</th>
                                {
                                    user.role !== 'Auxiliar' && user.role !== 'Repartidor' &&
                                    <th>Último precio</th>
                                }
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(productOrders[order]).map((product, index) => {
                                    return(
                                        <tr key={productOrders[order][product].idProduct}>
                                            <td>{productOrders[order][product].name}</td>
                                            <td>{productOrders[order][product].shortDescription}</td>
                                            <td>{productOrders[order][product].totalQuantity} {productOrders[order][product].pluralName}</td>
                                            {
                                                user.role !== 'Auxiliar' && user.role !== 'Repartidor' &&
                                                <td>{productOrders[order][product].price}</td>
                                            }
                                            <td hidden>
                                                <Button className='SecondaryBtn' id={productOrders[order][product].idProduct} onClick={(e) => {modifyProductsAvailability(e)}}>Marcar como agotado</Button>
                                            </td>
                                        </tr>  
                                    );
                                })
                            }
                        </tbody>
                    </Table>         
                    {
                        user.role !== 'Auxiliar' && user.role !== 'Repartidor' &&
                        <Table>
                            <tbody>
                                <tr>
                                    <td colSpan={10} className='text-end'>Costo: <b>${Array.from(productOrders[order]).reduce((partialSum, product) => partialSum + (product.cost*product.totalQuantity), 0)}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={10} className='text-end'>Precio de venta: <b>${Array.from(productOrders[order]).reduce((partialSum, product) => partialSum + (product.soldPrice*product.totalQuantity), 0)}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={10} className='text-end'>Utilidad: <b>${Array.from(productOrders[order]).reduce((partialSum, product) => partialSum + ((product.soldPrice-product.cost)*product.totalQuantity), 0)}</b></td>
                                </tr>
                            </tbody>
                        </Table>
                    }
                </div>
                )})}
        </Container>
    );
}
export default DisplayOrdersBySupplier;