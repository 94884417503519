import './components.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Environment from '../views/Environment';

function CancelClientOrderModal(props) {

  function CancelOrder(e){
    const idOrder = props.id;
    
    fetch(`${Environment()}/orders/cancelOrder`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({idOrder:idOrder, isCanceled:props.isCanceled})
    })
    .then(() => {
        window.location.reload();
    })
    .catch(err => {
        console.error(err);
    });
}

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.isCanceled? 'Quitar cancelación de pedido' : 'Cancelar pedido'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          ¿Estás seguro de que quieres <b className='text-danger'>{props.isCanceled? 'quitar la cancelación del' : 'cancelar el'}</b> pedido con ID {props.id} de {props.name} creado el {props.datetime}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={props.onHide}>Cancelar</Button>
        <Button className='btn-danger' onClick={(e) => CancelOrder(e)}>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CancelClientOrderModal;