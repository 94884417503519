import React, { useState, useEffect, useRef } from 'react';
import Container from "react-bootstrap/Container";
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import Form from 'react-bootstrap/Form';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import ProgressBar from 'react-bootstrap/ProgressBar';
import AdminBar from "../components/AdminBar";
import CancelClientOrderModal from '../components/cancelClientOrderModal';
import ModifyClientOrder from '../components/modifyClientOrder';
import Environment from './Environment';
import Spinner from 'react-bootstrap/esm/Spinner';
import './views.css';

function Order(){
    let { idOrder } = useParams();
    const [order, setOrder] = useState({});
    const [orderProgress, setOrderProgress] = useState(1);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [updatedProduct, setUpdatedProduct] = useState(0);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const [errorText, setErrorText] = useState('');
    const childRef = useRef(null);
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const [spinner, setSpinner] = useState(false);


    function modifyOrder(attribute, value){
        fetch(`${Environment()}/orders/modifyOrder`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...order, 
                [attribute] : value
            })
        })
        .then(response => response.json())
        .then((response) => {
            if(response.status === 200){
                setOrder({
                    ...order, 
                    [attribute] : value
                });
            } else {
                setErrorText(Array.from(response.details).map((product, idx) => (
                    <li hidden={product.quantity <= product.available ? true : false} key={product.idProduct}>{product.name}: {Number(product.available)} {Number(product.quantity) > 1 ? product.pluralName + " disponibles." : product.singularName + " disponible."}</li>
                )))
            }
        })
        .catch(err => {
            console.error(err);
        });
    }

    useEffect(() => {
        const temp = encodeURIComponent(JSON.stringify([idOrder]));
        fetch(`${Environment()}/orders/getOrdersById?orders=${temp}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setOrder(data[0]);
            let progress = 1;
            if(data[0].isSent){
                progress += 25;
            }
            if(data[0].isOutForDelivery){
                progress += 25;
            }
            if(data[0].isReceived){
                progress += 25;
            }
            if(data[0].isPayed){
                progress += 25;
            }
            if(progress > 100){
                progress = 100;
            }
            setOrderProgress(progress)
        })
        .catch(err => {
            console.error(err);
        });
    }, [idOrder, order])

    const deliveryButton = (
        <Col>
            <Button disabled={order.roleID === 1} onClick={() => {modifyOrder('isOutForDelivery', !order.isOutForDelivery)}} variant='outline-success'>
                <ion-icon item-start size='large' name="boat-outline" class="icon"></ion-icon>
            </Button>
            <Form.Check
                disabled 
                label='Productos enviados'
                title='Marcar como recibido'
                defaultChecked={order.isOutForDelivery}
            />
        </Col>
    );

    const receivedButton = (
        <Col>
            <Button disabled={order.roleID === 1} onClick={() => {modifyOrder('isReceived', !order.isReceived)}} variant='outline-success'>
                <ion-icon item-start size='large' name="nutrition-outline" class="icon"></ion-icon>
            </Button>
            <Form.Check
                disabled 
                label='Productos entregados'
                title='Marcar como recibido'
                defaultChecked={order.isReceived}
            />
        </Col>
    );

    const payedButton = (
        <Col>
            <Button disabled={order.roleID === 1} onClick={() => {modifyOrder('isPayed', !order.isPayed)}} variant='outline-success'>
                <ion-icon item-start size='large' name="card-outline" class="icon"></ion-icon>
            </Button>
            <Form.Check
                disabled
                label='Pagado'
                title='Marcar como pagado'
                defaultChecked={order.isPayed}
            />
        </Col>
    )

    function HandleDownload(e){
        e.preventDefault();
        if (childRef.current) {
            setSpinner(true);
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const element = childRef.current
            element.hidden = false;
            html2canvas(element).then(canvas => {
                canvas.toBlob(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `pedido#${idOrder}_${order.firstName + ' ' + order.lastName}_${new Date().toLocaleDateString('es-MX', options)}.png`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    element.hidden = true;
                    setSpinner(false);
                }, 'image/png');
            });
        }
    }

    function sendWhatsApp(e){
        e.preventDefault();
        setSpinner(true);
        const element = childRef.current
        element.hidden = false;
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        html2canvas(element)
            .then(function(canvas) {
                    fetch(`${Environment()}/orders/sendWhatsAppOrder`, {
                        method: 'POST',
                        headers: {
                        'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            recipient: order.phone,
                            name: order.firstName + ' ' + order.lastName,
                            deliveryDate: new Date(order.estimatedDelivery).toLocaleDateString('es-MX', options),
                            image: canvas.toDataURL('image/png'),
                            idOrder: idOrder
                        })
                    }).then(() => {
                        element.hidden = true;
                        setError('');
                        setSuccess('Mensaje enviado exitosamente.');
                        setSpinner(false);
                    })
                    .catch(err => {
                        console.error(err);
                        setSuccess('');
                        setError('Ocurrió un error al enviar el mensaje.');
                        setSpinner(false);
                    });
            })
            .catch(err => {
                console.error(err);
                setSpinner(false);
            });
    }

    return(
        <Container className='Layout'>
            <Row>
                <Breadcrumb>
                <Breadcrumb.Item href="/administracion">Inicio</Breadcrumb.Item>
                <Breadcrumb.Item href="/pedidos">Historial de pedidos</Breadcrumb.Item>
                <Breadcrumb.Item active>Pedido de {order.firstName + " " + order.lastName}</Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <Row>
                <AdminBar active='/pedidos'></AdminBar>
            </Row>
            <Row className='mb-4'>
                <h3 className='mb-3'><b>Pedido de <span className='green'>{order.firstName + " " + order.lastName}</span></b></h3>
                <h3 className='mb-3 text-danger' hidden={!order.isCanceled}><b>Cancelado</b></h3>
                <div className='text-muted'>
                    <p>Pedido el: {new Date(order.dateTime).toLocaleDateString('es-MX', options)}</p>
                    <p>Esperado el: {new Date(order.estimatedDelivery).toLocaleDateString('es-MX', options)}</p>
                    {
                        order.isReceived === 1 && order.isCanceled === 0 &&
                        <p className='text-muted'>Entregado el: {new Date(order.deliveryDatetime).toLocaleDateString('es-MX', options)} a las {new Date(order.deliveryDatetime).toLocaleTimeString('es-MX')}</p>
                    }
                    <p>ID: {idOrder}</p>
                </div>
            </Row>
            <Row hidden={order.isCanceled}>
                <Col>
                    <Button  crossOrigin="anonymous" variant='outline-success'>
                        <ion-icon item-start size='large' name="reader-outline" class="icon"></ion-icon>
                    </Button>
                    <Form.Check
                        disabled
                        label='Pedido recibido'
                        defaultChecked={order.isSent}
                    />
                </Col>

                {deliveryButton}

                {receivedButton}

                {payedButton}
            
            </Row>
            <Row hidden={order.isCanceled} className='text-start mt-3'>
                <Col>
                    <ProgressBar variant="success" now={orderProgress} />
                </Col>
            </Row>
            <p className="text-danger" hidden={!errorText}>No hay suficiente inventario para marcar los productos como entregados. Elige una cantidad menor o eliminalos del carrito:</p>
            <ul className="text-danger">
                {errorText}
            </ul>
            <div className='mt-1 text-center'>
                <Spinner
                hidden={!spinner}
                as="span"
                className='justify-content-center'
                animation="border"
                variant='success'
                size="lg"
                role="status"
                />
            </div>
            <ModifyClientOrder ref={childRef} client={order.firstName + ' ' + order.lastName} disabled={order.isReceived} updatedProduct={updatedProduct} setUpdatedProduct={setUpdatedProduct} idOrder={order.idOrder}/>
            <Row>
                <Col sm={2}>
                    <Button
                    disabled={order.isReceived} 
                    variant='success' 
                    className='mt-5 mb-5'
                    onClick={(e) => {HandleDownload(e)}}>
                    Descargar nota
                    </Button>
                </Col>
                <Col sm={3}>
                <Button
                    disabled={order.isReceived} 
                    variant='success' 
                    className='mt-5 mb-5'
                    onClick={(e) => {sendWhatsApp(e)}}>
                    Enviar nota por WhatsApp
                    </Button>
                </Col>
                <Col>
                    <Button
                    disabled={order.isReceived} 
                    variant='danger' 
                    className='mt-5 mb-5'
                    onClick={(e) => {setShowCancelModal(true)}}>
                        { order.isCanceled ? 'Habilitar pedido' : 'Cancelar pedido'}
                    </Button>
                </Col>
                <Col>
                    <Spinner
                    hidden={!spinner}
                    as="span"
                    className='justify-content-center'
                    animation="border"
                    variant='success'
                    size="lg"
                    role="status"
                    />
                </Col>
            </Row>
            {error && <Alert variant='danger'>{error}</Alert>}
            {success && <Alert variant='success'>{success}</Alert>}
            <CancelClientOrderModal
            id={idOrder}
            name={order.firstName + ' ' + order.lastName}
            datetime={new Date(order.dateTime).toLocaleDateString('es-MX', options) + ' a las ' + new Date(order.dateTime).toLocaleTimeString('es-MX')}
            show={showCancelModal}
            isCanceled={order.isCanceled}
            onHide={() => setShowCancelModal(false)}
            />
        </Container>
    );
}
export default Order;