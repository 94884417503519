import React, { useState, useEffect } from 'react';
import NavbarUser from './../components/navbar';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import FooterUser from '../components/footerUser';
import Environment from './Environment';
import veggies from './../images/veggies.jpg';
import NewPostModal from '../components/newPostModal';

function AboutUs() {

    const [posts, setPosts] = useState([]);
    const [user, setUser] = useState({});
    const [modalShowNew, setModalShowNew] = useState(false);

    useEffect(() => {
        fetch(`${Environment()}/login/isLoggedIn`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(session => session.json())
        .then((session) => {
            setUser(session);
        })
    }, [])

    useEffect(() => {
        fetch(`${Environment()}/posts/getRecentPosts`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setPosts(data);
        })
        .catch(err => {
            console.error(err);
        });
    }, [])

    const cardPosts = posts.map(post => {
        let video = '';

        if (post.video){
            video = post.video.split('/');
            if(video.length === 4){
                video = video[3].split('?')[0]
            } else {
                video  = '';
            }
        }

        return(
            <Col className='mb-5 mt-3' xs={8} sm={6} lg={4} key={post.idPost}>
                <Card>
                    { post.image !== null &&
                        <Card.Img src={`${Environment()}/post${post.idPost}${post.imgExtension}`}></Card.Img>
                    }
                    { post.video &&
                        <Card.Img src={`https://img.youtube.com/vi/${video}/hqdefault.jpg`} title="YouTube video player"></Card.Img>
                    }
                    <Card.Body>
                        <Card.Title>{post.title}</Card.Title>
                        <Button variant="secondary" href={`post/${post.idPost}`}>Saber más</Button>
                    </Card.Body>
                </Card>
            </Col>
        )
    })

    return (
        <div>
            <Row className='ps-5 pe-5 background-nav'>
                <NavbarUser active='/AboutUs'></NavbarUser>
            </Row>
            <Row className='ps-5 pe-5 grey-green-bg Layout d-none d-md-block'>
                <p className="fs-3 text-center"><b>Bienvenido a Terra Viva: Nutriendo cuerpos, restaurando la tierra</b></p>                        
                <Row className='mt-5'>
                    <Col xs lg="4" className='me-5'>
                        <Image style={{ width: "calc(200px + 500vw)" }} fluid='true' src={veggies} ></Image>
                    </Col>
                    <Col>    
                        <p className="justify">En Terra Viva, no solo cultivamos alimentos orgánicos, sino que también somos artesanos de la regeneración. Nos enorgullece ser los nuevos miembros de la Sociedad Cooperativa de Productores del Tianguis Orgánico de Chapingo, y estamos en un emocionante camino hacia la Certificación Orgánica Participativa, que planeamos alcanzar a finales de este año.</p>
                        <p className="justify">Nuestra pasión es más que cultivar; es un compromiso con la tierra y con un futuro más saludable. Abrazamos prácticas regenerativas que no solo devuelven a la naturaleza, sino que también elevan la tierra y su producción a nuevos niveles de vitalidad. A través de enfoques de labranza mínima y sinergias sintrópicas, estamos reconstruyendo ecosistemas, revitalizando nutrientes y fomentando la prosperidad de la vida en todas sus formas.</p>
                        <p className="justify">Creemos profundamente en la armonía de la vida. Para nosotros, el manejo de los animales va más allá de la agricultura: es un acto de respeto y amor hacia las criaturas que comparten nuestro agrosistema. Cada ser es una pieza vital del rompecabezas que construye nuestro entorno, y trabajamos incansablemente para garantizar que tengan vidas dignas y felices.</p>
                        <p className="justify">Cuando eliges nuestros productos, no solo estás eligiendo alimentos nutritivos y deliciosos; estás eligiendo un camino hacia un bienestar más amplio. Cada bocado que das es un voto por la salud de tu cuerpo y la del planeta. Imagina una vida en sintonía con la naturaleza, donde cada elección nutre tu ser interior y el mundo que te rodea.</p>
                        <p className="justify">Te extendemos una invitación abierta para unirte a nosotros en este viaje. Prueba nuestros productos y siente la diferencia en cada bocado. Terra Viva es mucho más que una marca: es una promesa de cuidado, de revitalización y de una conexión más profunda con la tierra y sus dones.</p>
                        <p>¡Ven, deléitate y cambia el mundo con cada elección!</p>
                        <p>Tu equipo en Terra Viva</p>
                    </Col>
                </Row>
                <h3 className='mt-5 text-center'><b>Novedades</b></h3>
                <Row>
                    {cardPosts}
                    {
                        user.isLoggedIn && (user.role === 'Coordinación' || user.role === 'Gerencia') &&
                        <Col className='mb-5 mt-3' xs={8} sm={6} lg={4}>
                            <Card className='h-100'>
                                <Button onClick={() => setModalShowNew(true)} className='align-self-center h-100 w-100' variant='light'><ion-icon size={'large'} name="add-circle-outline"/></Button>
                            </Card>
                        </Col>
                    }
                </Row>
            </Row>
            <Row className='d-md-none text-center justify-content-center mb-5'>
                <Row className='mt-5'>
                        <Image style={{ width: "calc(150px + 400vw)" }} fluid='true' src={veggies} ></Image>
                        <p className="fs-3 mt-2"><b>Bienvenido a Terra Viva: Nutriendo cuerpos, restaurando la tierra</b></p>
                        <p className="justify">En Terra Viva, no solo cultivamos alimentos orgánicos, sino que también somos artesanos de la regeneración. Nos enorgullece ser los nuevos miembros de la Sociedad Cooperativa de Productores del Tianguis Orgánico de Chapingo, y estamos en un emocionante camino hacia la Certificación Orgánica Participativa, que planeamos alcanzar a finales de este año.</p>
                        <p className="justify">Nuestra pasión es más que cultivar; es un compromiso con la tierra y con un futuro más saludable. Abrazamos prácticas regenerativas que no solo devuelven a la naturaleza, sino que también elevan la tierra y su producción a nuevos niveles de vitalidad. A través de enfoques de labranza mínima y sinergias sintrópicas, estamos reconstruyendo ecosistemas, revitalizando nutrientes y fomentando la prosperidad de la vida en todas sus formas.</p>
                        <p className="justify">Creemos profundamente en la armonía de la vida. Para nosotros, el manejo de los animales va más allá de la agricultura: es un acto de respeto y amor hacia las criaturas que comparten nuestro agrosistema. Cada ser es una pieza vital del rompecabezas que construye nuestro entorno, y trabajamos incansablemente para garantizar que tengan vidas dignas y felices.</p>
                        <p className="justify">Cuando eliges nuestros productos, no solo estás eligiendo alimentos nutritivos y deliciosos; estás eligiendo un camino hacia un bienestar más amplio. Cada bocado que das es un voto por la salud de tu cuerpo y la del planeta. Imagina una vida en sintonía con la naturaleza, donde cada elección nutre tu ser interior y el mundo que te rodea.</p>
                        <p className="justify">Te extendemos una invitación abierta para unirte a nosotros en este viaje. Prueba nuestros productos y siente la diferencia en cada bocado. Terra Viva es mucho más que una marca: es una promesa de cuidado, de revitalización y de una conexión más profunda con la tierra y sus dones.</p>
                        <p>¡Ven, deléitate y cambia el mundo con cada elección!</p>
                        <p>Tu equipo en Terra Viva</p>                        
                </Row>
                <h3 className='text-center'><b>Novedades</b></h3>
                <Row className='justify-content-center'>
                    {cardPosts}
                    {
                        user.isLoggedIn && (user.role === 'Coordinación' || user.role === 'Gerencia') &&
                        <Col className='mb-5 mt-3' xs={8} sm={6} lg={4}>
                            <Card className='h-100'>
                                <Button onClick={() => setModalShowNew(true)} className='align-self-center h-100 w-100' variant='light'><ion-icon size={'large'} name="add-circle-outline"/></Button>
                            </Card>
                        </Col>
                    }
                </Row>
            </Row>
            <FooterUser active='/AboutUs'></FooterUser>
            <NewPostModal
            show={modalShowNew}
            onHide={() => setModalShowNew(false)}
            title='Nuevo post'
            description=''
            />
        </div>
        
    );
}

export default AboutUs;
