import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Environment from '../views/Environment';
import NewSupplierModal from './newSupplierModal';
import ModifySupplierModal from './modifySupplierModal';
import DeleteSupplierModal from './deleteSupplierModal';
import SupplierProductsModal from './supplierProductsModal';


function SupplierTable(){

    const [suppliers, setSuppliers] = useState([]);
    const [suppliersSearch, setSuppliersSearch] = useState([]);
    const [modalShowNew, setModalShowNew] = useState(false);
    const [modalShowModify, setModalShowModify] = useState(false);
    const [modalShowProducts, setModalShowProducts] = useState(false);
    const [alert, setAlert] = React.useState(true);
    const [alertText, setAlertText] = React.useState('');
    const [supplierModify, setSupplierModify] = useState(null);

    const[modalShowDelete, setModalShowDelete] = useState(false);
    const[deletedSupplierName, setDeletedSupplierName] = useState('');
    const[deletedSupplierId,setDeletedSupplierId] = useState('');
    const[supplierProductsName, setSupplierProductsName] = useState('');
    const[supplierProductsId,setSupplierProductsId] = useState('');

    function handleShowModify(e){
        e.preventDefault();
        setSupplierModify(e.currentTarget.id);
        setModalShowModify(true);
    }

    function filterSuppliers(e){
        e.preventDefault();
        const supplier = e.currentTarget.value;
        if(!supplier){
            setSuppliersSearch('');
        }
        else{
            setSuppliersSearch(suppliers?.filter((row) => row?.name?.match(new RegExp(supplier, "i"))));
        }
    }

    useEffect(() => {
        fetch(`${Environment()}/suppliers/getSuppliers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setSuppliers(data);
        })
        .catch(err => {
            console.error(err);
            setAlert(false);
            setAlertText('Lo sentimos, en este momento no es posible mostrar los proveedores.')
        });
    }, [])
    
    function DeleteSupplier(e){
        setModalShowDelete(true);
        setDeletedSupplierName(e.currentTarget.name);
        setDeletedSupplierId(e.currentTarget.id);
    }

    function ShowProducts(e){
        setModalShowProducts(true);
        setSupplierProductsName(e.currentTarget.name);
        setSupplierProductsId(e.currentTarget.id);
    }

    const categoryRows = Array.from((suppliersSearch.length < 1) ? suppliers : suppliersSearch).map((supplier, idx) => (
        <tr key={supplier.idSupplier}>
          <td>{supplier.name}</td>
          <td>{supplier.shortDescription}</td>
          <td>{supplier.longDescription}</td>
          <td>{supplier.address}</td>
          <td>{supplier.phone}</td>
          <td><Button id={supplier.idSupplier} name={supplier.name} onClick={(e) => ShowProducts(e)} className='SecondaryBtn'><ion-icon name="list-sharp"></ion-icon></Button></td>
          <td><Button id={supplier.idSupplier} onClick={(e) => handleShowModify(e)} className='SecondaryBtn'><ion-icon name="pencil-sharp"></ion-icon></Button></td>
          <td><Button id={supplier.idSupplier} name={supplier.name} onClick={(e) => DeleteSupplier(e)} variant="outline-danger"><ion-icon name="trash-outline"></ion-icon></Button></td>
        </tr>                     
    ));


    return(
        <Container>
            <Alert hidden={alert} variant='danger'>{alertText}</Alert>
            <Table>
                <thead>
                    <tr>
                        <td><Form.Control
                            placeholder="Buscar proveedor"
                            className="me-4"
                            style={{width:'auto'}}
                            onChange={(e) => {filterSuppliers(e)}}
                            />
                        </td>
                        <td colSpan={7} className='text-end'><Button onClick={() => setModalShowNew(true)} className='PrimaryBtn'><ion-icon className='Icon' slot="start" name="add-outline"></ion-icon> Crear proveedor</Button></td>
                    </tr>
                    <tr>
                        <th>Nombre</th>
                        <th>Descripción corta</th>
                        <th>Descripción larga</th>
                        <th>Dirección</th>
                        <th>Teléfono</th>
                        <th>Ver productos</th>
                        <th>Modificar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {categoryRows}
                </tbody>
            </Table>
            <NewSupplierModal
            show={modalShowNew}
            onHide={() => setModalShowNew(false)}
            title='Crear proveedor'
            description=''
            />
            <ModifySupplierModal
            show={modalShowModify}
            onHide={() => setModalShowModify(false)}
            title='Modificar proveedor'
            description=''
            id={supplierModify}
            />
            <DeleteSupplierModal
            show={modalShowDelete}
            onHide={() => setModalShowDelete(false)}
            name={deletedSupplierName}
            id={deletedSupplierId}
            />
            <SupplierProductsModal
            show={modalShowProducts}
            onHide={() => setModalShowProducts(false)}
            name={supplierProductsName}
            idsupplier={supplierProductsId}
            />
        </Container>
    );
}

export default SupplierTable;