import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import AdminBar from '../components/AdminBar';
import UserTable from '../components/userTable';
import './views.css';

function AdminUsers() {

  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout'>
        <Row>
          <Col>
            <h3 className='mb-5'><b>Administrar <span className='green'>usuarios</span></b></h3>
          </Col>
          <Col className='text-end'>
            <Button className='BuyButton' href='/nuevousuario'><ion-icon name="person-outline"></ion-icon><small> Nuevo cliente</small></Button>
          </Col>
        </Row>
        <UserTable />
      </Container>
    </Container>
  );
}

export default AdminUsers;