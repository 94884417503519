import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Environment from '../views/Environment';
import './components.css';

function ModifyCategoryModal(props) {

  const [formError, setFormError] = useState('');
  const [categoryId, setCategoryId] = useState(props.id);
  const [category, setCategory] = useState(
    {
      name: '',
      shortDescription: '',
      image: '',
      imgExtension: '',
      idCategory: '',
    } 
  );

  useEffect(() => {

    if(categoryId === null && props.id !== null){
      setCategoryId(props.id);
    }
     
    fetch(`${Environment()}/categories/getCategoryById/${categoryId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      if(data.length){
        setCategory(
          {
            name:data[0].name,
            shortDescription:data[0].shortDescription,
            idCategory:data[0].idCategory,
            imgExtension:data[0].imgExtension,
            image:'',
          });
      }
    })
    .catch(err => {
        console.error(err);
    });
    
}, [categoryId, props.id])

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setCategory((prev) => ({ ...prev, ...value }));
  }

   /**
   * onSubmit
   * @description Posts package product through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(category.image && category.image.type !== "image/png" && category.image.type !== "image/jpg" && category.image.type !== "image/jpeg"){
        setFormError("La imagen no es png, jpg o jpeg.");
        return;
      }

      const formData = new FormData();
      formData.append('image', category.image);
      formData.append('name', category.name);
      formData.append('shortDescription', category.shortDescription);
      formData.append('idCategory', category.idCategory);

      fetch(`${Environment()}/categories/getCategory/${category.name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        if(data.length && data[0].idCategory !== category.idCategory){
          setFormError("El nombre de categoría ya existe");
          return;
        }
        else{
          fetch(`${Environment()}/categories/modifyCategory/${category.idCategory}`, {
            method: 'POST',
            body: formData
          });
          setCategory(
            {
              name: '',
              shortDescription: '',
              image: '',
              imgExtension: '',
              idCategory: ''
            }
            );
            
            window.location.reload();
          }
      })
  }

  const onFileChange = (e) => {
    if(e.target.files.length ===0) {
      updateForm({ image: ''});
    }
    else{
      updateForm({ image: e.target.files[0] })
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputCategoryName">Nombre</Form.Label>
              <Form.Control
                required
                type="text"
                id="inputCategoryName"
                aria-describedby="categoryName"
                placeholder="Verduras"
                onChange={(e) => updateForm({ name: e.target.value })}
                value={category.name}
                />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputCategoryDesc">Descripción corta</Form.Label>
              <Form.Control
                required
                type="text"
                id="inputCategoryDesc"
                aria-describedby="categoryDescription"
                placeholder="Hortalizas, bulbos, raíces y más"
                onChange={(e) => updateForm({ shortDescription: e.target.value })}
                value={category.shortDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputCategoryImage">Imagen</Form.Label>
              <Form.Control 
                type='file'
                id='inputImage'
                aria-describedby="categoryImage"
                onChange={(e) => onFileChange(e)}
              />
              <Form.Text>Selecciona una imagen png, jpg o jpeg.</Form.Text>
            </Form.Group>
            { category.imgExtension && !category.image &&
            <Image 
              className='mb-3 mx-auto d-block'
              style={{ width: "calc(150px + 10vw)" }}
              src={`${Environment()}/category${categoryId}${category.imgExtension}`}>
            </Image>
            }
            { category.image &&
              <Image 
                className='mb-3 mx-auto d-block'
                style={{ width: "calc(150px + 10vw)" }}
                src={URL.createObjectURL(category.image)}>
              </Image>
            }
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ModifyCategoryModal;