import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Environment from '../views/Environment';
import './components.css';

function NewProductModal(props) {

  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [UMs, setUMs] = useState([]);
  const [UMObject, setUMObject] = useState({});
  const [formError, setFormError] = useState('');
  const [newProduct, setNewProduct] = useState(
    {
      name: '',
      shortDescription: '',
      longDescription: '',
      image: '',
      idCategory: '',
      idSupplier: '',
      UM: '',
      idFinalUnit: '',
      isPriceRecalculated: '',
      unitRelation: '',
      brutePrice: '',
      price: '',
      discount: '',
      cost: '',
      seasonStartDay: '',
      seasonStartMonth: '',
      seasonEndDay: '',
      seasonEndMonth: '',
    }
  );

  //Disable scroll on number inputs
  useEffect(() => {
    document.addEventListener("wheel", function(event){
      if(document.activeElement.type === "number"){
          document.activeElement.blur();
      }
    });
  }, [])

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {});

  useEffect(() => {
    fetch(`${Environment()}/categories/getCategories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
        setCategories(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/suppliers/getSuppliers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setSuppliers(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setUMs(data);
      setUMObject(arrayToObject(data, 'idUnit'));
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setNewProduct((prev) => ({ ...prev, ...value }));
  }

   /**
   * onSubmit
   * @description Posts category through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(newProduct.image.type !== "image/png" && newProduct.image.type !== "image/jpg" && newProduct.image.type !== "image/jpeg"){
        setFormError("La imagen adjuntada no es png, jpg o jpeg.")
        return;
      }

      if(!newProduct.UM || !newProduct.idCategory || !newProduct.idSupplier){
        setFormError("Llena todos los campos.")
        return;
      }

      const formData = new FormData();
      formData.append('image', newProduct.image);
      formData.append('name', newProduct.name);
      formData.append('shortDescription', newProduct.shortDescription);
      formData.append('longDescription', newProduct.longDescription);
      formData.append('idCategory', newProduct.idCategory);
      formData.append('idSupplier', newProduct.idSupplier);
      formData.append('UM', newProduct.UM);
      formData.append('idFinalUnit', newProduct.idFinalUnit);
      formData.append('isPriceRecalculated', newProduct.isPriceRecalculated);
      formData.append('unitRelation', newProduct.unitRelation);
      formData.append('price', newProduct.price);
      formData.append('discount', newProduct.discount);
      formData.append('cost', newProduct.cost);
      formData.append('seasonStart', newProduct.seasonStartDay && newProduct.seasonStartMonth ? (newProduct.seasonStartDay + "," + newProduct.seasonStartMonth) : null);
      formData.append('seasonEnd', newProduct.seasonEndDay && newProduct.seasonEndMonth ? (newProduct.seasonEndDay + "," + newProduct.seasonEndMonth) : null);

      fetch(`${Environment()}/products/getProduct/${newProduct.name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        if(data.length){
          setFormError("El nombre del producto ya existe");
          return;
        }
        else{
          fetch(`${Environment()}/products/postProduct`, {
            method: 'POST',
            body: formData
          });
          
          setNewProduct(
            {
              name: '',
              shortDescription: '',
              longDescription: '',
              image: '',
              idCategory: '',
              idSupplier: '',
              UM: '',
              idFinalUnit: '',
              isPriceRecalculated: '',
              unitRelation: '',
              price: '',
              discount: '',
              cost: '',
              seasonStartDay: '',
              seasonStartMonth: '',
              seasonEndDay: '',
              seasonEndMonth: ''
            }
          );
            
          window.location.reload();
        }
      })
  }

  const months = [
    {number:1, name:'enero'}, 
    {number:2, name:'febrero'},
    {number:3, name:'marzo'},
    {number:4, name:'abril'},
    {number:5, name:'mayo'},
    {number:6, name:'junio'},
    {number:7, name:'julio'},
    {number:8, name:'agosto'},
    {number:9, name:'septiembre'},
    {number:10, name:'octubre'},
    {number:11, name:'noviembre'},
    {number:12, name:'diciembre'}
  ];

  const selectMonths = Array.from(months).map((month, idx) => (
  <option key={month.number} value={month.number}>{month.name}</option>
  ));

  const categorySelect = Array.from(categories).map((category, idx) => (
    <option key={category.idCategory} value={category.idCategory}>{category.name}</option>                    
  ));

  const supplierSelect = Array.from(suppliers).map((supplier, idx) => (
    <option key={supplier.idSupplier} value={supplier.idSupplier}>{supplier.name}</option>                    
  ));

  const UMSelect = Array.from(UMs).map((UM, idx) => (
    <option key={UM.idUnit} value={UM.idUnit}>{UM.singularName}</option>                    
  ));

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b>{props.title}</b>
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Nombre *</b></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Manzana roja"
                onChange={(e) => updateForm({ name: e.target.value })}
                value={newProduct.name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Descripción corta *</b></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Manzana Royal Gala"
                onChange={(e) => updateForm({ shortDescription: e.target.value })}
                value={newProduct.shortDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputProductLongDesc"><b>Descripción larga</b></Form.Label>
              <Form.Control
                as="textarea" 
                rows={3}
                placeholder="Manzanas jugosas que le encantarán a tu familia."
                onChange={(e) => updateForm({ longDescription: e.target.value })}
                value={newProduct.longDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Categoría *</b></Form.Label>
              <Form.Select
                required
                onChange={(e) => updateForm({ idCategory: e.target.value })}
                value={newProduct.idCategory}
              >
                <option value=''>Selecciona una categoría</option>  
                {categorySelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Proveedor *</b></Form.Label>
              <Form.Select
                required
                onChange={(e) => updateForm({ idSupplier: e.target.value })}
                value={newProduct.idSupplier}
              >
                <option value=''>Selecciona un proveedor</option>  
                {supplierSelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Unidad de medida pedido *</b></Form.Label>
              <Form.Select
                required
                type="text"
                placeholder="KG"
                onChange={(e) => updateForm({ UM: e.target.value })}
                value={newProduct.UM}
              >
                <option value=''>Selecciona una unidad de medida</option>
                {UMSelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Unidad de medida venta *</b></Form.Label>
              <Form.Select
                required
                type="text"
                placeholder="KG"
                onChange={(e) => updateForm({ idFinalUnit: e.target.value })}
                value={newProduct.idFinalUnit}
              >
                <option value=''>Selecciona una unidad de medida</option>
                {UMSelect}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>¿El producto se tiene que pesar o medir antes de la entrega? *</b></Form.Label>
              <Form.Select
                required
                type="text"
                placeholder="KG"
                onChange={(e) => {updateForm({ isPriceRecalculated : e.target.value }); updateForm({ unitRelation: e.target.value === 'false' ? 1 : newProduct.unitRelation })}}
                value={newProduct.isPriceRecalculated}
              >
                <option value=''>Selecciona una opción</option>
                <option value={true}>Si</option>
                <option value={false}>No</option>
              </Form.Select>
            </Form.Group>
            <Form.Label className='green'><b>Escribe la relación que tienen apróximadamente ambas unidades de medida *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>1 {UMObject[newProduct.UM] ? UMObject[newProduct.UM].singularName : '' } es igual a </InputGroup.Text>
              <Form.Control
                required
                disabled={newProduct.isPriceRecalculated === 'false'}
                type="number"
                placeholder="30.99"
                step={ UMObject[newProduct.idFinalUnit] ? UMObject[newProduct.idFinalUnit].isFloat === 1 ? '0.001' : '1' : ''}
                min={UMObject[newProduct.idFinalUnit] ? UMObject[newProduct.idFinalUnit].isFloat === 1 ? '0.001' : '1' : ''}
                onChange={(e) => {updateForm({ unitRelation: e.target.value})}}
                value={newProduct.unitRelation}
              />
              <InputGroup.Text>{UMObject[newProduct.idFinalUnit]? UMObject[newProduct.idFinalUnit].singularName : ''}</InputGroup.Text>
            </InputGroup>
            <Form.Label className='green'><b>Costo (costo de producción o pago al productor) *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                placeholder="12"
                min={0}
                onChange={(e) => updateForm({ cost: e.target.value })}
                value={newProduct.cost}
              />
            </InputGroup>
            <Form.Label className='green'><b>Precio (precio a los clientes de Terra Viva) *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                placeholder="30.99"
                min={newProduct.cost}
                onChange={(e) => {updateForm({ brutePrice: e.target.value}); updateForm({ price: e.target.value - newProduct.discount});}}
                value={newProduct.brutePrice}
              />
            </InputGroup>
            <Form.Label className='green'><b>Descuento *</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                type="number"
                placeholder="0.99"
                min={0}
                max={newProduct.brutePrice}
                onChange={(e) => { updateForm({ discount: e.target.value }); updateForm({ price: newProduct.brutePrice - e.target.value});}}
                value={newProduct.discount}
              />
            </InputGroup>
            <Form.Label className='green'><b>Precio con descuento</b></Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                required
                disabled
                type="number"
                placeholder="30"
                min={0}
                value={newProduct.price}
              />
            </InputGroup>
            <Form.Label className='green'><b>Inicio de temporada</b></Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                value={newProduct.seasonStartDay}
                min={1}
                max={31}
                onChange={(e) => updateForm({ seasonStartDay: e.target.value })}
                placeholder='Escribe un día'
                >
              </Form.Control>
              <Form.Select
                type="text"
                value={newProduct.seasonStartMonth}
                onChange={(e) => updateForm({ seasonStartMonth: e.target.value })}
                >
                <option value=''>Selecciona un mes</option>
                {selectMonths}
              </Form.Select>
            </InputGroup>
            <Form.Label className='green'><b>Fin de temporada</b></Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                value={newProduct.seasonEndDay}
                min={1}
                max={31}
                placeholder='Escribe un día'
                onChange={(e) => updateForm({ seasonEndDay: e.target.value })}
              >
              </Form.Control>
              <Form.Select
                type="text"
                value={newProduct.seasonEndMonth}
                onChange={(e) => updateForm({ seasonEndMonth: e.target.value })}
              >
                <option value=''>Selecciona un mes</option>
                {selectMonths}
              </Form.Select>
            </InputGroup>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Imagen *</b></Form.Label>
              <Form.Control 
                required
                type='file'
                onChange={(e) => updateForm({ image: e.target.files[0] })}
              />
              <Form.Text>Selecciona una imagen png, jpg o jpeg.</Form.Text>
            </Form.Group>
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewProductModal;