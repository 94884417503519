import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AdminBar from '../components/AdminBar';
import OrderSupplierButton from '../components/orderSupplierButton';
import NewSupplierOrderModal from '../components/newSupplierOrderModal';
import Environment from '../views/Environment';
import './views.css';

function SuppliersOrders() {

  const [orders, setOrders] = useState({});
  const [showNewModal, setShowNewModal] = useState('');
  const [ordersSearch, setOrdersSearch] = useState([]);
  const [orderYears, setOrderYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedStatus, setSelectedStatus] = useState('Todos');

  useEffect(() => {
    fetch(`${Environment()}/supplierOrder/getOrders/${selectedYear}/${selectedMonth}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setOrders(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [selectedYear, selectedMonth])

  useEffect(() => {
    fetch(`${Environment()}/supplierOrder/getOrderYears`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setOrderYears(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  function filterOrders(e){
    e.preventDefault();
    const order = e.currentTarget.value;
    if(!order){
      setOrdersSearch('');
    }
    else{
      setOrdersSearch(orders?.filter((row) => row?.supplierName?.match(new RegExp(order, "i"))));
    }
  }


  const orderButtons = Array.from((ordersSearch.length < 1) ? orders : ordersSearch).map((order, idx) => (
    <Col key={order.idOrder}>
      <OrderSupplierButton status={selectedStatus} isSent={order.isSent} isPayed={order.isPayed} isReceived={order.isReceived} isCanceled={order.isDeleted} idOrder={order.idOrder} dateTime={order.dateTime} supplierName={order.supplierName}/>                    
    </Col>
  ));

  const selectYears = Array.from(orderYears).map((year, idx) => (
    <option key={year.year} value={year.year}>{year.year}</option>
  ));

  const months = [
                  {number:0, name:'enero'}, 
                  {number:1, name:'febrero'},
                  {number:2, name:'marzo'},
                  {number:3, name:'abril'},
                  {number:4, name:'mayo'},
                  {number:5, name:'junio'},
                  {number:6, name:'julio'},
                  {number:7, name:'agosto'},
                  {number:8, name:'septiembre'},
                  {number:9, name:'octubre'},
                  {number:10, name:'noviembre'},
                  {number:11, name:'diciembre'}
                ];
  const selectMonths = Array.from(months).map((month, idx) => (
    <option key={month.number} value={month.number}>{month.name}</option>
  ));

  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout mb-5'>
        <Row>
          <Col>
            <h3 className='mb-5'><b>Entradas de <span className='green'>inventario</span></b></h3>
          </Col>
          <Col className='d-flex align-items-end flex-column'>
            <Button onClick={() => {setShowNewModal(true)}} className='PrimaryBtn p-2'><ion-icon name="add-outline"></ion-icon> Crear pedido</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Select value={selectedYear} onChange={(e) => {setSelectedYear(e.currentTarget.value)}}>
              {selectYears}
            </Form.Select>
          </Col>
          <Col>
            <Form.Select value={selectedMonth} onChange={(e) => {setSelectedMonth(e.currentTarget.value)}}>
            {selectMonths}
            </Form.Select>
          </Col>
          <Col>
            <Form.Control
            placeholder="Buscar por proveedor"
            onChange={(e) => {filterOrders(e)}}
            />
          </Col>
          <Col>
            <Form.Select value={selectedStatus} onChange={(e) => {setSelectedStatus(e.currentTarget.value)}}>
              <option value="Todos">Todos</option>
              <option value="Sin enviar">Sin enviar</option>
              <option value="Por recibir">Por recibir</option>
              <option value="Completado">Completado</option>
              <option value="Cancelado">Cancelado</option>
            </Form.Select>
          </Col>
        </Row>
        <Row xs={2} md={3} className="mt-3 g-4">
          {orderButtons}
        </Row>
      </Container>
      <NewSupplierOrderModal
      title={'Nueva entrada de inventario'}
      description={''}
      show={showNewModal}
      onHide={() => setShowNewModal(false)}
      />
    </Container>
  );
}

export default SuppliersOrders;