import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import logoTerraViva from './../images/logoTerraViva.png';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import './../components/components.css';
import './views.css'
import Environment from './Environment';
import NavbarUser from './../components/navbar';
import FooterUser from '../components/footerUser';

const LogIn = (props) => {
  const [form, setForm] = useState({
      username: '',
      password: '',
  });

  const [error, setError] = useState('');
  const bcrypt = require('bcryptjs');

  /**
   * onSubmit
   * @description Posts the auth data to the api and receives the status
   * @param e: Context
   */
  async function onSubmit(e) {
    e.preventDefault()
    const endline = document.getElementById('endline');
    let access = '';
    let hash = '';
    let isBlocked = false;
    fetch(`${Environment()}/login/getUser/${form.username}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((data) => data.json() )
    .then((data) => {
      if(data.length > 0 && data[0].roleID !== (1 || 7)){
        hash = data[0].password;
        access = bcrypt.compareSync(form.password, hash);
        isBlocked = data[0].isBlocked;
        if(isBlocked){
          setError('El usuario fue bloqueado por un administrador.');
          endline.hidden = true;
        } else if(!access){
          setError('El usuario o contraseña ingresados son incorrectos.');
          endline.hidden = true;
        } else if(e.error){
            setError('Lo sentimos, en este momento no es posible procesar tu solicitud.');
            endline.hidden = true;
        } else{
          fetch(`${Environment()}/login`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({username:form.username}),
          })     
          .then(() => {
            window.location.assign("/inicio");
          })
          .catch(err => {
            console.log(err);
            setError('Lo sentimos, en este momento no es posible procesar tu solicitud.');
            endline.hidden = true;
          })
        }
      } else {
        setError('El usuario o contraseña ingresados son incorrectos.');
          endline.hidden = true;
      }
    })
    .catch(err => {
      console.log(err);
      setError('Lo sentimos, en este momento no es posible procesar tu solicitud.');
      endline.hidden = true;
    })
  }

  /**
  * updateForm
  * @description updates data of a form
  * @param value: new values of the form
  * @returns an updated form
  */
  function updateForm(value) {
      return setForm((prev) => ({ ...prev, ...value }));
  }

  return (
    <div className='grey-green-bg'>
      <NavbarUser active='/login'></NavbarUser>
      <Container className='vh-100 d-flex align-items-center justify-content-center'>
        <Row className='d-flex align-items-center justify-content-center'>
          <Col md={6} xs={10} className={'text-center mb-5'}>
            <img src={logoTerraViva} alt="logo" className="h-25 w-50" />
          </Col>
          <Col>
            <Card className='shadow LoginCard'>
              <form onSubmit={onSubmit} id="loginForm">
                <Card.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Correo electrónico</Form.Label>
                    <Form.Control type="text" id="user" placeholder="nombre@ejemplo.com" onChange={(e) => updateForm({ username: e.target.value })} required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control type="password" id="password" onChange={(e) => updateForm({ password: e.target.value })} required/>
                  </Form.Group>
                  <Card.Link href="/recuperarCuenta">Olvidé mi contraseña</Card.Link>
                  <p className="text-danger">{error}</p>
                  <br id="endline" />
                </Card.Body>
                <Card.Footer className="text-muted d-grid gap-2">
                  <Button className='PrimaryBtn' type='submit' form='loginForm'>Iniciar sesión</Button>
                  <Button className='SecondaryBtn' href='/registro'>Crear una cuenta</Button>
                </Card.Footer>
              </form>
            </Card>
          </Col>
        </Row>
      </Container>
      <FooterUser active='/login'></FooterUser>
    </div>
  );
}

export default LogIn;
