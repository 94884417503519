import Container from 'react-bootstrap/Container';
import AdminBar from '../components/AdminBar';
import SupplierTable from '../components/supplierTable';
import './views.css';

function AdminSuppliers() {
  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout'>
        <h3 className='mb-5'><b>Administrar <span className='green'>proveedores</span></b></h3>
        <SupplierTable/>
      </Container>
    </Container>
  );
}

export default AdminSuppliers;