import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import Alert from 'react-bootstrap/esm/Alert';
import NavbarUser from '../components/navbar';
import FooterUser from '../components/footerUser';
import Environment from './Environment';
import { useParams } from 'react-router-dom';
import './views.css';

const bcrypt = require('bcryptjs');

function ResetMyPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { email, token } = useParams();

  function passwordValidation() {
    const regex = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,32}$/
    if(password !== confirmPassword){
      setError('Las contraseñas no coinciden');
      return false;
    }
    else if(!regex.test(password)){
      setError('La contraseña no tiene por lo menos una mayúscula, una minúscula, un número, entre 8 y 32 caracteres y un caracter especial.');
      return false;
    }
    return true;
  }

  function passwordSubmit(){
    if(passwordValidation()){
      const hash = bcrypt.hashSync(password, 10);
      fetch(`${Environment()}/login/restorePassword`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({email:email, password:hash, userToken:token})
      })
      .then((response) => {
          if(response.status === 200){
            window.location.href = '/login';
          }
          else if(response.status === 600){
            setError('El correo no existe.');
          }
          else if(response.status === 601){
            setError('El link ha expirado. Solicita un nuevo link.');
          }
          else if(response.status === 500){
            setError('Lo sentimos, en este momento no podemos realizar la solicitud. Inténtalo más tarde.')
          }
      })
      .catch(err => {
          console.error(err);
          setError('Lo sentimos, en este momento no podemos realizar la solicitud. Inténtalo más tarde.')
      });
    }
  }
  return (
    <Container>
      <NavbarUser></NavbarUser>
      <Container className='Layout'>
        <h3 className='mb-2'><b>Restableciemiento de contraseña</b></h3>
        <h5 className='mb-5'>¿Has olvidado tu contraseña? Escribe tu nueva contraseña.</h5>
        <Form >
          <Form.Label>Nueva contraseña</Form.Label>
          <Form.Control required value={password} onChange={(e) => {setPassword(e.currentTarget.value)}} type='password' className='mb-2' size='lg'/>
          <Form.Label>Repetir contraseña</Form.Label>
          <Form.Control required value={confirmPassword} onChange={(e) => {setConfirmPassword(e.currentTarget.value)}} type='password' className='mb-2' size='lg'/>
          <Button className='mb-5' onClick={() => {passwordSubmit()}} variant='success' size='lg'>Cambiar contraseña </Button>
        </Form>
        <Alert hidden={error? false:true} variant='danger'>{error}</Alert>
      </Container>
      <FooterUser active='/login'></FooterUser>
    </Container>
  );
}

export default ResetMyPassword;