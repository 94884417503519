import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import Environment from '../views/Environment';
import './components.css';

function ModifySupplierModal(props) {

  const [formError, setFormError] = useState('');
  const [supplier, setSupplier] = useState(
    {
      name: '',
      shortDescription: '',
      longDescription: '',
      address: '',
      phoneCode: '',
      phone: '',
      idSupplier: parseInt(props.id),
      selectedDays:[]
    }
  );
  const daysOfWeek = [
    {1:'Lunes'},
    {2:'Martes'},
    {3:'Miércoles'},
    {4:'Jueves'},
    {5:'Viernes'},
    {6:'Sábado'},
    {7:'Domingo'}
  ];
  const [variants, setVariants] =  useState({
    1:'outline-secondary',
    2:'outline-secondary',
    3:'outline-secondary',
    4:'outline-secondary',
    5:'outline-secondary',
    6:'outline-secondary',
    7:'outline-secondary',
  });

  function updateDay(value) {
    return setVariants((prev) => ({ ...prev, ...value }));
  }

  useEffect(() => {
    if(props.id){
      fetch(`${Environment()}/suppliers/getSupplierById/${props.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        const phoneCode = String(data[0].phone).slice(0, -10);
        const phone = String(data[0].phone).slice(-10);
        updateForm(data[0]);
        updateForm({phoneCode:phoneCode});
        updateForm({phone:phone});
        updateForm({idSupplier:parseInt(props.id)});
        
        let i=1;
        let j=0;
        let selectedDaysCopy = [];
        while(i < 8){
          if(j < data[1].length && data[1][j].idDay === i){
            // If the day number was registered, the button type is secondary
            updateDay({[i]:'secondary'});
            selectedDaysCopy.push(i);
            j++;
          }
          else{
            // If the day number was not registered, the button type is outline
            updateDay({[i]:'outline-secondary'});
          }
          i++;
        }
        updateForm({'selectedDays':selectedDaysCopy});
      })
      .catch(err => {
          console.error(err);
      });
    }
    
  }, [props.id])

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setSupplier((prev) => ({ ...prev, ...value }));
  }

  function setDay(e){
    e.preventDefault();
    const idDay = parseInt(e.currentTarget.id);
    const variant = variants[idDay];
    let selectedDaysCopy = supplier['selectedDays'];

    if(variant === 'outline-secondary'){
      updateDay({[idDay]:'secondary'});
      selectedDaysCopy.push(idDay);
    }
    else{
      updateDay({[idDay]:'outline-secondary'});
      const index = selectedDaysCopy.indexOf(idDay);
      selectedDaysCopy.splice(index, 1);
    }
    
    updateForm({'selectedDays':selectedDaysCopy});
  }

   /**
   * onSubmit
   * @description Posts package product through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      fetch(`${Environment()}/suppliers/getSupplierByName/${supplier.name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        if(data.length > 0 && parseInt(data[0].idSupplier) !== parseInt(props.id)){
          setFormError("El proveedor ya existe.");
          return;
        }
        else{
          fetch(`${Environment()}/suppliers/modifySupplier`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(supplier)
          })
          .then(() => {
            window.location.reload();
          })
          .catch(error => {
            console.log(error);
            setFormError('Lo sentimos, en este momento no es posible procesar tu solicitud.')
          });
        }
      })
      .catch(error => {
        console.log(error);
        setFormError('Lo sentimos, en este momento no es posible procesar tu solicitud.')
      });
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputSupplierName"><b>Nombre*</b></Form.Label>
              <Form.Control
                required
                type="text"
                id="inputSupplierName"
                maxLength={32} 
                aria-describedby="supplierName"
                placeholder="Hortalizas Pérez"
                onChange={(e) => updateForm({ name: e.target.value })}
                value={supplier.name}
              />
            </Form.Group>
            <Form.Group  className="mb-3">
              <Form.Label className='green' htmlFor="inputSupplierDesc"><b>Descripción corta*</b></Form.Label>
              <Form.Control
                required
                type="text"
                maxLength={255} 
                id="inputSupplierDesc"
                aria-describedby="supplierDescription"
                placeholder="Hortalizas, bulbos, raíces y más"
                onChange={(e) => updateForm({ shortDescription: e.target.value })}
                value={supplier.shortDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green' htmlFor="inputSupplierLongDesc"><b>Descripción larga</b></Form.Label>
              <Form.Control
                as="textarea" rows={3}
                id="inputSupplierLongDesc"
                aria-describedby="SupplierLongDescription"
                placeholder="Hortalizas Pérez tiene más de 10 años produciendo alimentos orgánicos en Querétaro."
                onChange={(e) => updateForm({ longDescription: e.target.value })}
                onKeyUp={(e) => updateForm({ longDescription: e.target.value })}
                value={supplier.longDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Entregas a clientes</b></Form.Label>
              <p className='text-muted'>Selecciona los días de la semana que se entregarán los productos de este proveedor a los clientes de Terra Viva.</p>
              <InputGroup className="mb-3">
                { 
                  daysOfWeek.map((day, idx) => {
                    return(
                    <Button 
                    variant={variants[idx+1]} 
                    id={idx+1} 
                    key={idx+1}
                    onClick={(e) => {setDay(e)}}>{day[idx+1]}</Button>)
                  })
                }
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Dirección*</b></Form.Label>
              <Form.Control 
                required 
                type="text" 
                maxLength={64} 
                onChange={(e) => updateForm({ address: e.target.value })}
                value={supplier.address}
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3">
              <Form.Label className='green'><b>Teléfono*</b></Form.Label>
              <InputGroup>
                <DropdownButton
                  variant="outline-secondary"
                  title={supplier.phoneCode}
                  id="input-group-dropdown-2"
                  align="end"
                  required
                >
                  <Dropdown.Item onClick={(e) => updateForm({ phoneCode: '1' })}>+1</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => updateForm({ phoneCode: '52' })}>+52</Dropdown.Item>
                </DropdownButton>
                <Form.Control 
                  required 
                  type="tel" 
                  id="phone" 
                  maxLength={10}
                  onChange={(e) => updateForm({ phone: e.target.value })}
                  value={supplier.phone}
                />
              </InputGroup>
            </Form.Group>
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ModifySupplierModal;