import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminBar from '../components/AdminBar';
import CardAdminHome from '../components/cardAdminHome';
import Environment from './Environment';
import './views.css';

function AdminHome(props) {
  const management = ['Gerencia'];
  const coordinator = ['Coordinación', 'Gerencia'];
  const delivery = ['Repartidor', 'Coordinación', 'Gerencia'];
  const assistant = ['Auxiliar', 'Repartidor', 'Coordinación', 'Gerencia'];

  const [user, setUser] = useState({});

  useEffect(() => {
      fetch(`${Environment()}/login/isLoggedIn`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(session => session.json())
      .then((session) => {
          setUser(session);
      })
  }, [])

  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout'>
        <h3 className='mb-5'><b>Panel de <span className='green'>administración</span></b></h3>
            <Container className='mb-5'>
              <h4 ><b>Ventas</b></h4>
              <Row className='mb-3'>
                {
                  delivery.includes(user.role) &&
                  <Col>
                    <CardAdminHome title='Comenzar repartición' icon='car-sport' link='/entregarProductos/true'/>
                  </Col>
                }
                {
                  assistant.includes(user.role) &&
                  <Col>
                    <CardAdminHome title='Pedidos de clientes' icon='basket-outline' link='/pedidos' />
                  </Col>
                }
              </Row>
              <Row className='mb-3'>
                { 
                  assistant.includes(user.role) &&
                  <Col>
                    <CardAdminHome title='Inventario' icon='cube-outline' link='/inventario'/>
                  </Col>
                }
                {
                  management.includes(user.role) &&
                  <Col>
                    <CardAdminHome title='Administrar usuarios' icon='person-add-outline' link='/gestionarusuarios'/>
                  </Col>
                }
              </Row>
            </Container>
            {
              coordinator.includes(user.role) &&
              <Container className='mb-5'>
                <h4><b>Producción</b></h4>
                <Row className='mb-3'>
                  { management.includes(user.role) &&
                    <Col>
                      <CardAdminHome title='Entradas de inventario' icon='call-outline' link='pedidosproveedores'/>
                    </Col>
                  }
                  { management.includes(user.role) &&
                    <Col>
                      <CardAdminHome title='Gestionar proveedores' icon='people-outline' link='/proveedores'/>
                    </Col>
                  }
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <CardAdminHome title='Gestionar productos' icon='nutrition-outline' link='/gestionarproductos'/>
                  </Col>
                </Row>
              </Container>
            }
            {
              coordinator.includes(user.role) &&
              <Container className='mb-5'>
                <h4><b>Finanzas</b></h4>
                <Row className='mb-3'>
                  {
                    management.includes(user.role) &&
                    <Col>
                      <CardAdminHome title='Estado de resultados' icon='receipt-outline' link='/estadoDeResultados'/>
                    </Col>
                  }
                  {
                    management.includes(user.role) &&
                    <Col>
                      <CardAdminHome title='Balance' icon='wallet-outline' link='/balanceGeneral'/>
                    </Col>
                  }
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <CardAdminHome hidden={management.includes(user.role)} title='Métricas' icon='bar-chart-outline' link='/metricas'/>
                  </Col>
                </Row>
              </Container>
            }
      </Container>
    </Container>
  );
}

export default AdminHome;