import Container from 'react-bootstrap/esm/Container';
import AdminBar from '../components/AdminBar';
import SalesByProduct from '../components/salesByProduct';
import SalesBySellPoint from '../components/salesBySellPoint';
import './views.css';

function Metrics() {
  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout'>
        <h3 className='mb-2 green'><b>Métricas</b></h3>
        <SalesByProduct/>
        <SalesBySellPoint/>
      </Container>
    </Container>
  );
}

export default Metrics;