import './components.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Environment from '../views/Environment';

function DeleteProductModal(props) {

  function DeleteProduct(e){
    const idProduct = props.id;
    
    fetch(`${Environment()}/products/deleteProduct`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({idProduct:idProduct})
    })
    .then(() => {
        window.location.reload();
    })
    .catch(err => {
        console.error(err);
    });
}

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Eliminar producto
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          ¿Estás seguro de que quieres eliminar este producto?
        </p>
        <p className='text-danger'>
          {props.name}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
        <Button className='btn-danger' onClick={(e) => DeleteProduct(e)}>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteProductModal;