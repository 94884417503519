import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/Button';
import NewProducts from '../components/NewProducts';
import NavbarUser from './../components/navbar';
import Categories from './../components/categories';
import PopularProducts from '../components/popularProducts';
import FooterUser from '../components/footerUser';
import FooterHome from '../components/footerHome';
import './views.css'
import 'moment/locale/es';

var moment = require('moment');
moment.locale('es');

function Home() {
  return (
    <div>
      <div className='ps-5 pe-5 background-nav'>
          <NavbarUser active='/inicio'></NavbarUser>
      </div>
      <div>
          <div className='text-center background-crop'>
            <div className='light-black-background outer-container'>
              <Container className='text-white-gray'>
                <h1 style={{'fontSize': '60px'}}>Porque somos la tierra</h1>
                <h2>Productos agroecológicos 100% mexicanos</h2>
                <Button href='/productos' className='mt-3' variant="success">Conocer productos</Button>
              </Container>
            </div>
          </div>
          <div className='NewsSection outer-container'>
            <Container>
              <h3 className='text-white'><b>Novedades</b></h3>
              <NewProducts></NewProducts>
            </Container>
          </div>
          <Categories></Categories>
          <div className='height80 outer-container'>
            <Container>
              <h3><b>Productos populares</b></h3>
              <PopularProducts></PopularProducts>
            </Container>
          </div>
        <FooterHome></FooterHome>
        <div className='d-block d-lg-none home-footer home-blue-bg'></div>
        <FooterUser active='/inicio' className='d-block d-lg-none'></FooterUser>

      </div>
    </div>
  );
}

export default Home;
